<template>
    <div class="w-10/12 m-auto py-4">
        <NavBar/>
    </div>
    <div class="bg-gray-50">
        <div class="w-10/12 m-auto flex lg:justify-center lg:gap-40 gap-10 lg:pt-32 pt-10 lg:pb-20 pb-16 items-center lg:flex-row flex-col-reverse">
            <span class="lg:w-5/12">
                <p class="text-4xl font-bold">{{ $t('emergency_services') }}</p>
                <p class="text-gray-600 mt-5">{{ $t('emergency_services_text') }}</p>

                <button class="bg-blue-50 text-gray-600 rounded-3xl mt-10 px-3 py-3 font-medium">{{ $t('coming_soon') }}</button>
            </span>
            <div class="lg:w-1/2 w-full lg:mx-0 mx-auto" >
                <img v-lazy="emergency" alt="" :class="{'hidden': isPort}">
                <img v-lazy="emergencyPort" alt="" :class="{'hidden': !isPort}">
            </div>
        </div>
    </div>

    <!-- How it works -->
    <div class="pt-20 w-10/12 mx-auto pb-28">
        <div class="">
            <h1 class="text-center text-4xl text-gray-900 font-bold">{{ $t('how_works') }}</h1>

            <div class="flex lg:pt-28 lg:justify-between lg:flex-row lg:items-start flex-col pt-16 ">
                
                <img v-lazy="people" alt="" class=" lg:w-2/5 mx-auto lg:mx-0 w-full">
            
                <div class="lg:w-2/5 pt-10 lg:pt-0">
                    <span>
                        <img v-lazy="one" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('register') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('download_passenger_app') }}</p>
                    </span>
                    <span>
                        <img v-lazy="two" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('set_location') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('make_request') }}</p>
                    </span>
                    <span>
                        <img v-lazy="three" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('emergency_services_respond') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('cater_to_emergencies') }}</p>
                    </span>
                </div>
            </div>
        </div>

    </div>

    <downloadRides/>
    <HomeFooter/>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import downloadRides from '@/components/downloadRides.vue';
import HomeFooter from '@/components/HomeFooter.vue';
    export default{
        data(){
            return{
                emergency: require('@/assets/emergency.png'),
                emergencyPort: require('@/assets/emergencyPort.png'),
                people: require('@/assets/people.png'),
                one: require('@/assets/one.svg'),
                two: require('@/assets/two.svg'),
                three: require('@/assets/three.svg'),
            }
        },
        components:{
            NavBar,
            downloadRides,
            HomeFooter
        },
        computed:{
        isFrench(){
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en';
        },
        
    },

    }
</script>