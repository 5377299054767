<template>
    <!-- Responsiveness for larger screens -->
    <div class="w-9/12 mx-auto lg:flex hidden justify-between lg:py-40 py-0">
        
        <div class="w-1/6 flex flex-col space-y-20">
            <span class=" text-right">
                <div class="flex justify-end">
                    <img v-lazy="blackOne" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('request_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('select_pickup') }}</p>   
            </span>

            <span class=" text-right">
                <div class="flex justify-end">
                    <img v-lazy="blackThree" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('start_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('driver_meets_you') }}</p>   
            </span>

        </div>

        <div class="relative flex justify-center items-center">
            <img v-lazy="blackiPhone" alt="" class="absolute h-[500px]" :class="{'hidden': !isEng}">
            <img v-lazy="blackFrench" alt="" class="absolute h-[500px]" :class="{'hidden': !isFrench}">
            <img v-lazy="blackPort" alt="" class="absolute h-[500px]" :class="{'hidden': !isPort}">
            <img v-lazy="blackCircle" class="" alt="">
        </div>

        <div class="sm:w-1/6 flex flex-col space-y-20">
            <span class=" text-left">
                <div class="flex justify-start">
                    <img v-lazy="blackTwo" class="" alt="">     
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('match') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('gab_will_match_you') }}</p>   
            </span>

            <span class=" text-left">
                <div class="flex justify-start">
                    <img v-lazy="blackFour" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('pay_and_give_ratings') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('pay_description') }}</p>   
            </span>

        </div>
        

    </div>

        <!-- Responsiveness for medium screens -->
        <div class="w-9/12 mx-auto hidden lg:justify-between sm:flex lg:hidden flex-col lg:py-40">
        
        <div class="relative flex justify-center items-center lg:pt-0 pt-20">
            <img v-lazy="blackiPhone" alt="" class="absolute h-[500px]" :class="{'hidden': !isEng}">
            <img v-lazy="blackFrench" alt="" class="absolute h-[500px]" :class="{'hidden': !isFrench}">
            <img v-lazy="blackPort" alt="" class="absolute h-[500px]" :class="{'hidden': !isPort}">
            <img v-lazy="blackCircle" class="" alt="">
        </div>

        <div class="flex pt-40 pb-20 justify-between">        
            <div class="w-2/5 flex flex-col space-y-20"
            :class="{'space-y-28':isFrench, 'space-y-28':isPort}">
            <span class=" text-right">
                <div class="flex justify-end">
                    <img v-lazy="blackOne" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('request_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('select_pickup') }}</p>   
            </span>

            <span class=" text-right">
                <div class="flex justify-end">
                    <img v-lazy="blackThree" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('start_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('driver_meets_you') }}</p>   
            </span>

            </div>
            <div class="w-2/5 flex flex-col space-y-20">
            <span class=" text-left">
                <div class="flex justify-start">
                    <img v-lazy="blackTwo" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('match') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('gab_will_match_you') }}</p>   
            </span>

            <span class=" text-left pt-1">
                <div class="flex justify-start">
                    <img v-lazy="blackFour" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('pay_and_give_ratings') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('pay_description') }} </p>   
            </span>

            </div>
        </div>
    </div>


        <!-- Responsiveness for small screens -->
        <div class="w-9/12 mx-auto lg:justify-between sm:hidden flex flex-col lg:py-40">
        
        <div class="relative flex justify-center items-center pt-40">
            <img v-lazy="blackiPhone" alt="" class="absolute h-[400px]" :class="{'hidden': !isEng}">
            <img v-lazy="blackFrench" alt="" class="absolute h-[400px]" :class="{'hidden': !isFrench}">
            <img v-lazy="blackPort" alt="" class="absolute h-[400px]" :class="{'hidden': !isPort}">
            <img v-lazy="blackCircle" class="" alt="">
        </div>

        <div class="pt-40 pb-20 flex flex-col space-y-20 items-center">        
            <div class="w-2/3 flex flex-col space-y-20">
            <span class=" text-center">
                <div class="flex justify-center">
                    <img v-lazy="blackOne" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('request_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('select_pickup') }}</p>   
            </span>
            <span class=" text-center">
                <div class="flex justify-center">
                    <img v-lazy="blackTwo" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('match') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('gab_will_match_you') }}</p>   
            </span>


            </div>

            <div class="w-2/3 flex flex-col space-y-20">
            <span class=" text-center">
                <div class="flex justify-center">
                    <img v-lazy="blackThree" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('start_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('driver_meets_you') }}</p>   
            </span>

            <span class=" text-center">
                <div class="flex justify-center">
                    <img v-lazy="blackFour" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('pay_and_give_ratings') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('pay_description') }}</p>   
            </span>

            </div>
        </div>






        </div>
</template>

<script>
    export default{

        data(){
            return{
                blackOne:require('@/assets/blackOne.svg'),
                blackTwo:require('@/assets/blackTwo.svg'),
                blackThree:require('@/assets/blackThree.svg'),
                blackFour:require('@/assets/blackFour.svg'),
                blackiPhone:require('@/assets/blackiPhone.png'),
                blackFrench:require('@/assets/frenchBlackiPhone.png'),
                blackPort:require('@/assets/portugueseBlackiPhone.png'),
                blackCircle:require('@/assets/blackCircle.svg'),
                
                
            }
        },
        computed:{
        isFrench(){
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en'
        }
    }

    }
</script>

