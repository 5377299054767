const messages={
    // NavBar
    home:'Home',
    about_us: 'About us',
    blog:'Gab Blog',
    services:'Services',
    contact:'Contact us',
    eng:'English',
    fren:'French',
    port:'Portuguese',
    become_driver:'Become a driver',
    rides:'Rides',
    ride_driver:'Driver',
    logistics:'Logistics',
    emergency:'Emergency Services',
    

    // Hero Section
    safe:'Safe and affordable ride.',
    thousands:'Thousands of people, organizations and enterprises depend on our transport services to move around the city.',
    request:'REQUEST A RIDE NOW',

    // Request a ride view
    ride_with_ease:'Ride with ease, great comfort, safety and arrive with confidence.',
    request_ride: 'Request now',

    // Drive and Earn
    drive:'Drive and Earn',
    find:'Find trips that fit a flexible schedule',
    plan:'You can drive for as long and as often as you choose. Weekdays, weekends and evenings -  plan your driving around your schedule.', 
    earn:'Earn more by driving with Gab',
    get_numerous_rides:'Get numerous ride request and earn more everyday.',
    earn_money_everyweek:'Earn money every week',
    drive_with_gab:'Want to earn money before payday? Drive with Gab now.',

    // How Gab works
    how:'How Gab works',
    passenger:'Passenger',
    driver:'Driver',

    // Passenger Info and Driver Info
    request_trip:'Request a trip',
    select_pickup:'Select your pickup and destination and select your ride option.',
    start_trip: 'Start trip',
    driver_meets_you: 'Driver meets with you with the help of our real-time GPS service and start trip.',
    match:'Match with driver',
    gab_will_match_you: 'Gab will match you to the next available driver that will drive you',
    end_trip:'End trip and pay',
    pay_for_your_trip:'Pay for your trip with cash or card, and rate driver. Enjoy your trip with Gab.',
    pay_and_give_ratings:'Pay and give ratings',
    pay_description: 'Pay for your trip with cash or card, and your driver',

    // Need a Ride?  
    need_ride:'Need a ride? We Gab you.',
    efficient_transport:'Efficient and Reliable Transportation',
    quick_booking:'Quick and easy booking process for instant transportation with round-the-clock service to cater to your transportation needs anytime.',
    simplify_commute:'Simplify Your Commute with Gab',
    streamlined_booking: 'Streamlined booking system for a stress-free experience. Track your ride in real-time for added safety and convenience.',
    experience_future:'Experience the Future of Ride-hailing',
    intuitive:'Intuitive and user-friendly app design for effortless navigation. Enhanced safety measures to ensure a secure ride.',

    // Blog and News
    blog_and_news:'Blog and News',
    see_all: 'See all',
    prioritize_safety: "It's important for passengers to prioritize their safety when using the service...",
    platforms_revolutionized: 'In the rapidly evolving world of transportation, e-hailing platforms have revolutionized...',
    launch_hailing_operations: 'E.F. Network Limited to launch its e-hailing operations in the country as...',

    // Download Gab Now
    download_gab: 'Download Gab App Now',
    sign_up_to_drive:'Sign up to drive',
    sign_up_to_ride:'Sign up to ride',

    // Home Footer
    company:'Company',
    aboutUs:'About Us',
    gab_blog:'Gab Blog',
    support_center:'Support Center',
    legal:'Legal',
    terms:'Terms and conditions',
    privacy_policy:'Privacy Policy',
    copyright:'Copyright 2023 Gab Taxi. All rights reserved.',
    address:'31 Pope John Paul Street, Maitama, Abuja, Nigeria.',
    address1:'31 Pope John Paul Street,',
    address2:'Maitama, Abuja, Nigeria.',

    // Services Rides
    got_you_covered:"Need a ride? We've got you covered",
    get_app:"Get the app",

    // Rides Options
    ride_options:'Ride Options',
    standard:'Standard',
    standard_text:"Just like your regular blue and white taxi, the Standard Drop option is cost-effective and takes you to your desired location without breaking the bank.\n\n While Standard Drop may not offer the luxurious features of other ride options, such as air conditioning, it provides a comfortable and safe ride to your destination. The drivers are vetted and trained to ensure a high level of professionalism, and the vehicles are regularly maintained to ensure reliability.",
    get_started:'Get Started',
    comfort:'Comfort',
    comfort_text:'One of the main features of Gab is its comfort option, which provides passengers with a comfortable ride at an affordable price. The vehicles used for the comfort option are well-maintained, clean, and equipped with comfortable seats and air conditioning, ensuring that passengers have a pleasant and comfortable ride.',
    vip:'VIP',
    vip_text:'For passengers who want to travel in style, Gab offers a VIP option. This option is perfect for those who want to arrive at their destination in luxury and comfort. The vehicles used for the VIP option are high-end cars that are designed to provide a luxurious experience. They are equipped with premium amenities such as leather seats, air conditioning, and entertainment systems, ensuring that passengers have a first-class experience.',
    tricycle:'Tricycle',
    tricycle_text:'We provided tricycle option for village users, where taxis are not available and also, where tricycles are not allowed in the townships. The positive user experiences we provide are bar none in the industry, because we have considered the need of every taxi user.',

    // Service Drivers
    drive_earn:'Drive and earn on your own terms',
    how_works:'How it works',
    choose_schedule:'Choose your own schedule',
    choose_schedule_text:"We understand that flexibility is important, and that’s why we offer a unique opportunity for drivers to work on their own schedule. \n\nAs a Gab Taxi driver, you have the freedom to choose when, where, and how often you work. Whether you want to make driving your full-time job or just earn some extra income on the side, we offer the flexibility to fit your needs.",
    support_success: 'Support and success',
    support_success_text:"We believe that our drivers are the backbone of our company, and we are committed to providing them with the support they need to succeed. We offer ongoing training and resources to help our drivers improve their skills and grow their businesses.\n\n At Gab Taxi, we pride ourselves on our commitment to safety. We carefully screen all of our drivers to ensure that they have a clean driving record and are knowledgeable about traffic laws and regulations. Additionally, we provide our drivers with tips and best practices for staying safe on the road.",
    competitive_rates:'Competitive rates',
    competitive_rates_text:'We also offer competitive rates and incentives to help our drivers maximize their earnings. With Gab Taxi, you can earn money on your own terms, while still enjoying the benefits of being a part of a dynamic and growing company. \n\nJoin the Gab Taxi team today and start driving on your own terms.',

    // Download Drive
    download_gab_now:'Download Gab Driver Now',

    // Emergency Services
    emergency_services:'Emergency Services',
    emergency_services_text:'Gab Taxi emergency services allow users to book ambulance, tow trucks, call police security hotline, waste management, and fire services during an emergency. Gab provides a quick and easy way for users to access emergency services at their fingertips. With Gab Taxi, users can request an ambulance for medical emergencies, tow trucks for car breakdowns or accidents, call the police security hotline for security threats, and the fire service in case of fires.',
    coming_soon:'Coming soon',
    register:'Register',
    download_passenger_app:'Download the Gab passenger app and register as a user.',
    set_location:'Set your emergency location',
    make_request: 'Select your location and make your request',
    emergency_services_respond:'Emergency services respond',
    cater_to_emergencies:'Services that cater to emergencies respond and come to your location.',

    // Gab Logistics
    gab_logistics:'Gab Logistics',
    gab_logistics_text:'We offer the general public the option to have their own personal messengers, who will do their errands for them without living the comfort of their offices or homes. Gab drivers also do deliveries and pickups.',
    select_dropoff:'Set pick-up and drop off',
    login_to_app:'Login to the app, select logistics and choose your pickup and drop off address.',
    item_delivered:'Your item is delivered',
    item_pickedUp:'Your item is picked up and delivered to your doorstep.',

    // Gab Blog
    safe_lifestyle:'The Safe and Affordable Lifestyle for You.',
    download_app:'Download our Apps',
    categories:'Categories',
    news:'News',
    safety:'Safety',
    places:'Places', 
    tips:'Tips',
    search:'Search',
    recent:'Recent Blog Posts',
    rise_of_ehailing:'The rise of e-hailing platforms such as ride-sharing and food delivery services, has revolutionized the way people commute and acces various goods and services.',
    gab_comfort:'How Gab brings you comfort',
    safety_for_public:'Safety for the public?',
    bustling_metropolis:'Abuja the capital city of Nigeria, is a bustling metropolis that offers a lot of opportunities.',
    blog_post:'All Blog Posts',
    advent_of_technology:'The advent of technology has revolutionized various sectors.',
    learn_more:'Learn more',
    organize_digital:'Organize your digital assets with a new methodology and there.',
    gab_owned:'Gab is owned by E.F. Network Limited and EFGroup.',
    first_indigenous:'The first indigenous e-hailing Gab taxi app',
    ehailing_services:'E-hailing services have become increasingly popular now.',
    giant_industry:'A giant in the Hi-tech industry and a son of the soil has a company.',

    // Contact Us
    contact_us: 'Contact Us',
    questions:'Questions or comments?',
    get_in_touch:' Get in touch.',
    reach_out:'Reach out to Us!',
    your_email:'Your Email',
    your_name:'Your Name',
    your_number:'Your Phone Number',
    your_message:'Your Message',
    email_support:'Email support at',
    call_us:'Call us at',

    // About Us
    affordable:'Affordable',
    about_us_text:'Welcome to Gab Taxi, the premier e-hailing application that connects you with reliable and affordable transportation options. At Gab Taxi, we are committed to providing our customers with safe, convenient, and hassle-free rides, whether you need to get to work, run errands, or simply want to explore your city.',
    safe_:'Safe',
    efficient:'Efficient',
    our_mission:'Our Mission',
    we_prioritize: 'We prioritize ',
    Safety:' safety',
    above_all:' above all else',
    we_prioritize_text:'At Gab Taxi, We carefully screen all of our drivers to ensure that they have a clean driving record and are knowledgeable about traffic laws and regulations. Additionally, we provide our drivers with ongoing training and support to ensure that they are equipped to handle any situation that may arise.',
    there_are:'There are',
    or:' or ',
    no_hidden_fees:'no hidden fees',
    surprise_charges: 'surprise charges',
    surprise_text:'We believe that transparency is key to building trust with our customers. That’s why we offer upfront pricing, so you know exactly how much your ride will cost before you even book it. We accept a variety of payment methods, including credit cards, debit cards, and cash, making it easy for you to pay for your ride in whatever way is most convenient for you.',
    exceptional_experience:'Exceptional experience just ',
    for_you:'for you ',
    exceptional_experience_text:'Our team is dedicated to creating an exceptional experience for our users, from the moment you book your ride to the time you arrive at your destination. We understand the importance of punctuality, which is why we work hard to ensure that our drivers are prompt and reliable, arriving at your location in a timely manner.',

    // Privacy Policy
    privacy_policy_text:'GabTaxi is committed to protecting the privacy of its drivers and passengers and ensuring the security of their personal information. This Privacy Notice outlines how GabTaxi processes and handles personal data in compliance with data protection laws and regulations.',
    personal_data:'1.   Personal Data We Process',
    personal_data_text:'GabTaxi processes the following personal data:',
    contact_details:'Contact details: name, phone number, and email address.',
    profile_info:'Profile information: profile picture, saved addresses, language, and communication preferences.',
    geolocation:'Geolocation: pick-up and drop-off location, journey progression, and final destination of the trip.',
    payment_info:'Payment information: the amount charged and the payment card used.',
    communication_and_correspondence : 'Communication and correspondence records: chat history with drivers or customer service agents.',
    limited_identification:'Limited identification data of the device: IP address on which the GabTaxi app is installed.',
    data_about_usage:'Data about the usage of transportation services: journey status, times, and conduct of passengers.',
    data_related:'Data related to communication through instant messages directly in the GabTaxi app: date and time of communication and its content.',
    other_user_generated_content:'Other user-generated content: photos of parked vehicles.',
    purpose_of_processing:'2.   Purpose of Processing',
    gab_processes:'GabTaxi processes personal data for the following purposes:',
    provide_transport:'To provide transportation services: to connect passengers with drivers, to show available vehicles, and to facilitate efficient pick-up and drop-of.',
    smooth_running:'To ensure the smooth running of transportation services: to make sure passengers reach their destination, to bill correctly, and to maintain the fleet.',
    optimize_app:'To optimize the app: to notify passengers of updates, to connect passengers with internet, mobile, and telephone services, and to keep accounts safe through authentication and verification checks.',
    improve_service:'To improve the service: to analyze geographic coverage, to recommend efficient routes, and to ensure that vehicles are in the most convenient locations for customers.',
    process_payment:"To process payment: to process payment details for ride-hailing on behalf of drivers and for GabTaxi’s hire-vehicles. To maintain and promote standards: to collect feedback, resolve disputes, and ensure user safety and compliance with terms and conditions.",
    communicate_with_passengers:'To communicate with passengers: to send trip confirmation slips, receipts, and service updates.',
    legal_basis:'3.   Legal Basis',
    gab_processes_on_legal_basis:'GabTaxi processes personal data on the legal basis of:',
    contract_to_provide:'Contract: to provide the services contracted through the GabTaxi app.',
    legitimate_interests:'Legitimate interests: to optimize the app, improve the service, maintain and promote standards, and communicate with passengers.',
    legal_obligations:'Legal obligations: to comply with legal obligations such as tax and accounting requirements.',
    consent:'Consent: to process personal data for certain purposes where consent has been given.',
    data_subject:'4.   Data Subject Rights',
    drivers_and_passengers:'Drivers and passengers have the following rights regarding their personal data:',
    right_to_access:'Right to access: to request access to personal data held by GabTaxi.',
    right_to_rectification:'Right to rectification: To request that personal data be restricted',
    right_to_erasure:'Right to erasure: to request that personal data be deleted.',
    right_to_restrict:'Right to restrict processing: to request that the processing of personal data be restricted.', 
    right_to_object:'Right to object: to object to the processing of personal data for certain purposes',
    right_to_data:'Right to data portability: to request a copy of personal data in a structured, machine-readable format.',
    right_to_withdraw_consent:'Right to withdraw consent: to withdraw consent where personal data is processed on the basis of consent.',
    data_protection:'5.   Data Protection',
    gab_committed_to_security:'GabTaxi is committed to ensuring the security of personal data and has implemented technical and organizational measures to prevent unauthorized access, use, or disclosure of personal data.',
    data_retention:'6.   Data Retention',
    gab_retains:'GabTaxi retains personal data for as long as necessary to provide the transportation services and for as long as required by law.',
    contact_us_:'7.   Contact Us',
    any_questions:'If you have any questions or concerns about how GabTaxi processes personal data, please contact our Data Protection Officer at ',
    personalDataWeProcess:'Personal Data We Process',
    purposeOfProcessing:'Purpose Of Processing',
    legalBasis:'Legal Basis',
    dataSubject:'Data Subject Rights',
    dataProtection:'Data Protection',
    dataRetention:'Data Retention',
    contactUs:'Contact Us',
    submit: 'Submit',

}




export default messages;