<template>
    <div class="w-10/12 m-auto py-4">
        <NavBar/>
    </div>
    <div class="bg-[url('@/assets/businessWoman.png')] h-[500px] lg:bg-cover bg-center">
        <div class="lg:w-5/12 sm:pl-20 pl-5 w-3/4 sm:w-7/12 lg:mx-0 lg:pl-32 pt-48">
        <p class="text-white lg:text-5xl text-3xl font-bold  leading-tight pb-10">{{ $t('got_you_covered') }}</p>
        <router-link to="/download-center" class="bg-customBlue text-white rounded-3xl px-8 py-4 font-medium">{{ $t('get_app') }}</router-link>            
        </div>
    </div>
        <RideOptions/>
    <div class="w-10/12 m-auto">

    </div>

    <downloadRides/>
    <HomeFooter/>
    
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import RideOptions from '@/components/RideOptions.vue'
import HomeFooter from '@/components/HomeFooter.vue'
import downloadRides from '@/components/downloadRides.vue'

export default{
    components:{
        NavBar,
        RideOptions,
        downloadRides,
        HomeFooter
    }
}
</script>