import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RequestRide from '../views/RequestRide.vue'
import AboutUs from '../views/AboutUs.vue'
import OurServices from '../views/OurServices.vue'
import ContactUs from '../views/ContactUs.vue'
import GabBlog from '../views/GabBlog.vue'
import ServicesRides from '../views/ServicesRides.vue'
import ServicesDrivers from '../views/ServicesDrivers.vue'
import ServicesLogistics from '../views/ServicesLogistics.vue'
import ServicesEmergency from '../views/ServicesEmergency.vue'
import downloadCenter from '../views/downloadCenter.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutUs
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactUs
  },
  {
    path: '/services',
    name: 'OurServices',
    component: OurServices
  },
  {
    path: '/request-ride',
    name: 'RequestRide',
    component: RequestRide
  },
  {
    path: '/gab-blog',
    name: 'GabBlog',
    component: GabBlog
  },
  {
    path: '/services-rides',
    name: 'ServicesRides',
    component: ServicesRides
  },
  {
    path: '/services-drivers',
    name: 'ServicesDrivers',
    component: ServicesDrivers
  },
  {
    path: '/services-logistics',
    name: 'ServicesLogistics',
    component: ServicesLogistics
  },
  {
    path: '/services-emergency',
    name: 'ServicesEmergency',
    component: ServicesEmergency
  },
  {
    path: '/download-center',
    name: 'DownloadCenter',
    component: downloadCenter
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
