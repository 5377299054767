<template>
    <!-- Responsiveness on larger devices -->
    <div class="border-t border-gray-100 hidden lg:block mt-40">
        <div class="w-10/12 mx-auto pt-32 ">
            <div class="flex flex-row justify-between items-start pb-20">
                <div class="flex lg:flex-row flex-col justify-between w-8/12">
                    <div>
                        <img v-lazy="gabLogo" class="w-[92px]" alt="">
                    </div>
                    <div class="flex lg:gap-40">
                    <div class="">
                        <p class="text-gray-900 text-lg font-bold pb-5">{{ $t('company') }}</p>
                        <span class="text-gray-500 flex flex-col space-y-3">
                            <router-link to="about">{{ $t('aboutUs') }}</router-link>
                            <!-- <a href="#">{{ $t('gab_blog') }}</a> -->
                            <a href="#">{{ $t('support_center') }}</a>
                        </span>

                    </div>            
                    <div class="">
                        <p class="text-gray-900 text-lg font-bold pb-5">{{ $t('legal') }}</p>
                        <span class="text-gray-500 flex flex-col space-y-3">
                            <a href="#">{{ $t('terms') }}</a>
                            <router-link to="/privacy-policy">{{ $t('privacy_policy') }}</router-link>
                        </span>

                    </div>                    
                    </div>
                
                </div> 
                
                <div class="flex flex-col items-end space-y-3">
                        <span class="flex gap-3 items-end">
                            <a href="https://www.facebook.com/MovewithGab" target="blank"><img v-lazy="facebook" alt=""></a>
                            <a href="https://www.instagram.com/movewith_gab" target="blank"><img v-lazy="instagram" alt=""></a>
                            <a href="https://twitter.com/Movewith_gab" target="blank"><img v-lazy="twitter" alt=""></a>
                        </span>
                        <p class="text-gray-500 text-right w-2/3">{{ $t('address') }}</p>
                </div>
            </div>

            <p class="text-sm text-gray-400 pb-10">{{ $t('copyright') }}</p>
        </div>        
    </div>


    <!-- Responsiveness on smaller devices -->
    <div class="border-t border-gray-100 block lg:hidden mt-20">
        <div class="w-10/12 mx-auto  pt-8">
            <div class="flex flex-col justify-between pb-20">
                <div>
                <img v-lazy="gabLogo" class="w-[92px]" alt="">
                </div>
                <div class="flex sm:flex-row sm:justify-between flex-col sm:space-y-0 space-y-10">
                    <span>
                        <p class="text-gray-900 text-lg font-bold pb-5">{{ $t('company') }}</p>
                        <span class="text-gray-500 flex flex-col space-y-3">
                            <router-link to="/about">{{ $t('aboutUs') }}</router-link>
                            <!-- <a href="#">{{ $t('gab_blog') }}</a> -->
                            <a href="#">{{ $t('support_center') }}</a>
                        </span>
                    </span>

                    <span>
                        <p class="text-gray-900 text-lg font-bold pb-5">{{ $t('legal') }}</p>
                        <span class="text-gray-500 flex flex-col space-y-3">
                            <a href="#">{{ $t('terms') }}</a>
                            <router-link to="/privacy-policy">{{ $t('privacy_policy') }}</router-link>
                        </span>
                    </span>
                    <span class="sm:w-1/5 w-4/5 flex flex-col space-y-3 sm:text-right text-left">
                        <span class="flex gap-3">
                            <a href="https://www.facebook.com/MovewithGab" target="blank"><img v-lazy="facebook" alt=""></a>
                            <a href="https://www.instagram.com/movewith_gab" target="blank"><img v-lazy="instagram" alt=""></a>
                            <a href="https://twitter.com/Movewith_gab" target="blank"><img v-lazy="twitter" alt=""></a>
                        </span>
                        <p class="text-gray-500">{{ $t('address') }}</p>
                    </span>
                </div>
                
                

            </div>

            <p class="text-sm text-gray-400 pb-10">{{ $t('copyright') }}</p>
        </div>        
    </div>

</template>

<script>
export default{
    data(){
        return{
            gabLogo: require('@/assets/gabLogo.png'),
            facebook: require('@/assets/facebook.svg'),
            instagram: require('@/assets/instagram.svg'),
            twitter: require('@/assets/twitter.svg'),
        }
    }
}
</script>