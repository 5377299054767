<template>
    <section id="main">
    <div class="w-10/12 mx-auto">
        <NavBar/>
    </div>
        <div class="pt-20">
            <p class="text-center py-4 sm:text-3xl text-xl bg-customBlue text-white lg:w-1/3 sm:w-1/2 w-11/12 font-bold mx-auto sm:mx-0">{{ $t('privacy_policy') }}</p>

            <div class="flex justify-around w-10/12 mx-auto pt-20 lg:flex-row flex-col-reverse">
                <div class="lg:w-[50%] text-gray-600">
                    <p class="text-lg text-gray-700 pt-16 lg:pt-0">GabTaxi is committed to protecting the privacy of its drivers and passengers and ensuring the security of their personal information. This Privacy Notice outlines how GabTaxi processes and handles personal data in compliance with data protection laws and regulations.</p>               
                    <section id="chapter1">
                    <p class="font-medium sm:text-3xl text-xl text-gray-900 pt-16">{{ $t('personal_data') }}</p>
                    <p class="pt-7 pb-5">{{ $t('personal_data_text') }}</p>
                    <ul class=" list-disc pl-5">
                    <li>{{ $t('contact_details') }}</li>
                    <li>{{ $t('profile_info') }}</li>
                    <li>{{ $t('geolocation') }}</li>
                    <li>{{ $t('payment_info') }}</li>
                    <li>{{ $t('communication_and_correspondence') }}</li>
                    <li>{{ $t('limited_identification') }}</li>
                    <li>{{ $t('data_about_usage') }}</li>
                    <li>{{ $t('data_related') }}</li>
                    <li>{{ $t('other_user_generated_content') }}</li>
                    </ul>
                    </section>

                    <section id="chapter2">
                        <p class="font-medium sm:text-3xl text-xl text-gray-900 pt-16">{{ $t('purpose_of_processing') }}</p>
                        <p  class="pt-7 pb-5">{{ $t('gab_processes') }}</p>
                        <ul class=" list-disc pl-5">
                        <li>{{ $t('provide_transport') }}</li>
                        <li>{{ $t('smooth_running') }}</li>
                        <li>{{ $t('optimize_app') }}</li>
                        <li>{{ $t('improve_service') }}</li>
                        <li>{{ $t('process_payment') }}</li>
                        <li>{{ $t('communicate_with_passengers') }}</li>
                        
                </ul>
                    
                    </section>

                    <section id="chapter3">
                        <p class="font-medium sm:text-3xl text-xl text-gray-900 pt-16">{{ $t('legal_basis') }}</p>
                        <p class="pt-7 pb-5">{{ $t('gab_processes_on_legal_basis') }}</p>
                        <ul class=" list-disc pl-5">
                        <li>{{ $t('contract_to_provide') }}</li>
                        <li>{{ $t('legitimate_interests') }}</li>
                        <li>{{ $t('legal_obligations') }}</li>
                        <li>{{ $t('consent') }}</li></ul>
                    </section>       
                    
                    <section id="chapter4">
                        <p class="font-medium sm:text-3xl text-xl text-gray-900 pt-16">{{ $t('data_subject') }}</p>
                        <p class="pt-7 pb-5">{{ $t('drivers_and_passengers') }}</p>
                        <ul class=" list-disc pl-5">
                            <li>{{ $t('right_to_access') }}</li>
                            <li>{{ $t('right_to_rectification') }}</li>
                            <li>{{ $t('right_to_erasure') }}</li>
                            <li>{{ $t('right_to_restrict') }}</li>
                            <li>{{ $t('right_to_object') }}</li>
                            <li>{{ $t('right_to_data') }}</li>
                            <li>{{ $t('right_to_withdraw_consent') }}</li>
                        </ul>
                    </section>

                    <section id="chapter5">
                        <p class="font-medium sm:text-3xl text-xl text-gray-900 pt-16">{{ $t('data_protection') }}</p>
                        <p class="pt-7">{{ $t('gab_committed_to_security') }}</p>
                    
                    </section>

                    <section id="chapter6">
                        <p class="font-medium sm:text-3xl text-xl text-gray-900 pt-16">{{ $t('data_retention') }}</p>
                        <p class="pt-7">{{ $t('gab_retains') }}</p>
                    </section>

                    <section id="chapter7">
                        <p class="font-medium sm:text-3xl text-xl text-gray-900 pt-16">{{ $t('contact_us_') }}</p>
                        <p class="pt-7">{{ $t('any_questions') }} <span class="text-customBlue underline">privacy@gabtaxi.com </span></p>
                    </section>
                </div>
                <div>
                    <p class="text-gray-700 font-semibold pb-4 text-lg">Table of content</p>
                    <ol class="list-decimal underline font-[900px] space-y-4 text-gray-800 pl-4 border-b pb-8">
                        <li><a href="#chapter1">{{ $t('personalDataWeProcess') }}</a></li>
                        <li><a href="#chapter2">{{ $t('purposeOfProcessing') }}</a></li>
                        <li><a href="#chapter3">{{ $t('legalBasis')}}</a></li>
                        <li><a href="#chapter4">{{ $t('dataSubject') }}</a></li>
                        <li><a href="#chapter5">{{ $t('dataProtection') }}</a></li>
                        <li><a href="#chapter6">{{ $t('dataRetention') }}</a></li>
                        <li><a href="#chapter7">{{ $t('contactUs') }}</a></li>
                    </ol>

                        <p class="text-gray-900 pt-6 text-xl items-center gap-5 lg:flex hidden"><a href="#main" class="text-gray-900 pt-6 text-xl items-center gap-5 lg:flex hidden">Back to the top<img v-lazy="arrowUp" alt="" class=""></a> </p>

                </div>
            </div>

        </div>        
    </section>


        <DownloadGab/>
        <HomeFooter/>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import DownloadGab from '@/components/DownloadGab.vue';
import HomeFooter from '@/components/HomeFooter.vue';
    export default{
        components:{
            NavBar,
            DownloadGab,
            HomeFooter
        },

        data(){
            return{
                arrowUp:require('../assets/arrow-up.svg')
            }
        },
        mounted(){
            window.scrollTo(0,0)
        }
    }
</script>