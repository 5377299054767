<template>
    <div class=" flex justify-between items-center">
        <router-link to="/"><img v-lazy="gabLogo" alt="" class="w-[92px]"></router-link>
        
        <div class="lg:flex lg:items-center lg:justify-between lg:w-10/12">
        <div class="lg:flex items-center gap-8 hidden " :class="textColor">
          <router-link to="/" exact-active-class="text-blue-500 underline">{{ $t('home') }}</router-link>
          <router-link to="/about" exact-active-class="text-blue-500 underline" class="">{{ $t('about_us') }}</router-link>

          <!-- Services navigation link -->
          <div>
            <button class="flex items-end gap-1 hover:text-blue-600" @click="Services" @mouseenter="Services"
            :class="{'text-customBlue':showServices}">{{ $t('services') }}
            <img v-lazy="caret" alt="">
            </button>
            
            <div class="bg-white absolute mt-3 w-[200px] rounded-lg text-black flex flex-col boxShadow" v-show="showServices">
              <router-link to="/services-rides" class=" px-2 mx-2 py-2 mt-2 hover:bg-gray-100 rounded-lg" exact-active-class="text-blue-500">{{ $t('rides') }}</router-link>
              <router-link to="/services-drivers" class="px-2 mx-2 py-2 hover:bg-gray-100 rounded-lg" exact-active-class="text-blue-500">{{ $t('ride_driver') }}</router-link>
              <router-link to="/services-logistics" class="py-2 px-2 mx-2 hover:bg-gray-100 rounded-lg" exact-active-class="text-blue-500">{{ $t('logistics') }}</router-link>
              <router-link to="/services-emergency" class="py-2 px-2 mx-2 hover:bg-gray-100 rounded-lg mb-2" exact-active-class="text-blue-500">{{ $t('emergency') }}</router-link>
            </div>
          </div>
          <router-link to="/contact" class="" exact-active-class="text-blue-500 underline">{{ $t('contact') }}</router-link>
          <a href="https://blog.gabtaxi.com" target="blank" class="" exact-active-class="text-blue-500 underline" :class="{'hidden':!isEng}">{{ $t('blog') }}</a>
        </div>

        <div class="flex items-center gap-10" :class="{'gap-5':isFrench}">

          <!-- Language Translation dropdown -->
         <div>
            <button class="flex items-center gap-2 " @click="toggleDropdown" exact-active-class="text-blue-600" :class="textColor">
              <img  :src="getLanguageImage(selectedLanguage)" class="rounded-3xl w-[20px]" alt="">
              {{ getLanguageCode(selectedLanguage) }} 
              <img v-if="dropdownOpen" v-lazy="caretDown" alt="">
              <img v-else v-lazy="caret" alt=""></button>

            <div v-if="dropdownOpen" class="">
                <ul class="absolute top-20 bg-white w-[200px] rounded-lg lg:right-56 sm:right-64 right-20 lg:pt-0 pt-3 boxShadow z-[50]">
                
                  <li class="py-2 mt-2 px-2 mx-2 gap-2 cursor-pointer hover:bg-gray-100 rounded-lg flex items-center" @click="selectLanguage('en')"><img v-lazy="english" class="w-[20px] h-[20px] rounded-full" alt="">English</li>
                  <li class="py-2 mt-2 px-2 mx-2 gap-2 cursor-pointer hover:bg-gray-100 rounded-lg flex items-center" @click="selectLanguage('portuguese')"><img v-lazy="portuguese" class="w-[20px] h-[20px] rounded-full" alt=""> Portuguese</li>
                  <li class="py-2 my-2 px-2 mx-2 gap-2 cursor-pointer hover:bg-gray-100 rounded-lg flex items-center" @click="selectLanguage('fr')"><img v-lazy="french" class="w-[20px] h-[20px] rounded-full" alt=""> French</li>
                  

                </ul>
            </div>
          </div> 

        <div class="lg:block hidden">
          <router-link to="download-center" class="bg-blue-500 text-white text-lg py-2 px-4 rounded-3xl">{{ $t('become_driver') }}</router-link>
          </div>
          </div>
        </div>

          <button @click="isOpen = !isOpen" class="block lg:hidden z-[100]" >
            <svg class="w-6 h-6" fill="none" stroke="currentColor" :class="{ 'hidden': isOpen, 'block': !isOpen }" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
            <svg class="w-6 h-6 relative" fill="none" stroke="currentColor" :class="{ 'hidden': !isOpen, 'block': isOpen, }" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
          </button>

    <div v-show="isOpen" class="lg:hidden absolute right-20 top-0 flex flex-col text-left pl-10 pt-6 bg-white w-full z-[50] left-0 h-[500px]" >
        <div class="space-y-10 p-3">
          <router-link to="/" exact-active-class="text-customBlue underline" class="font-bold">{{ $t('home') }}</router-link>
          <router-link to="/about" exact-active-class="text-customBlue underline" class="items-end gap-2 flex font-bold">{{ $t('about_us') }}</router-link>
          <!-- Services navigation link -->
          <div>
            <button class="flex items-end gap-1 font-bold" exact-active-class="text-customBlue underline" @click="Services">{{ $t('services') }}
            <img v-lazy="caret" alt="">
            </button>
            
            <div class="bg-white rounded-lg text-black flex flex-col" v-show="showServices">
              <router-link to="/services-rides" class="px-2 py-2 mt-2 hover:bg-gray-100 rounded-lg" exact-active-class="text-blue-500">{{ $t('rides') }}</router-link>
              <router-link to="/services-drivers" class="px-2 py-2 hover:bg-gray-100 rounded-lg" exact-active-class="text-blue-500">{{ $t('ride_driver') }}</router-link>
              <router-link to="/services-logistics" class="px-2 py-2 hover:bg-gray-100 rounded-lg" exact-active-class="text-blue-500">{{ $t('logistics') }}</router-link>
              <router-link to="/services-emergency" class="px-2 py-2 hover:bg-gray-100 rounded-lg mb-2" exact-active-class="text-blue-500">{{ $t('emergency') }}</router-link>
            </div>
          </div>
          <router-link to="/contact" class="items-end gap-2 flex font-bold" exact-active-class="text-customBlue underline ">{{ $t('contact') }}</router-link>
          <a href="/gab-blog" target="blank" class="items-end gap-2 flex font-bold" exact-active-class="text-customBlue underline" :class="{'hidden':!isEng}">{{ $t('blog') }} </a>
          <router-link to="/download-center" class=" items-end gap-2 bg-customBlue text-white py-2 px-4 rounded-3xl w-auto mt-10 absolute">{{ $t('become_driver') }}</router-link>
        </div>


      <router-link to="/download-center" class="lg:block bg-blue-500 text-white text-sm py-2 px-2 rounded-3xl hidden">{{ $t('become_driver') }}</router-link>
    </div>
        
      </div>
</template>

<script>
  import englishImage from "@/assets/english.svg";
  import portugueseImage from "@/assets/portuguese.svg";
  import frenchImage from "@/assets/french.svg";
    export default{
        props: {
          textColor: {
          type: String,
          default: 'text-black', 
        },
      },
        data(){
            return{
                isOpen: false,
                dropdownOpen: false,
                selectedLanguage: this.$i18n.locale,
                showServices:false,
                gabLogo:require('@/assets/gabLogo.png'),
                caret:require('@/assets/caret.svg'),
                caretDown:require('../assets/caret-down.svg'),
                english:require('../assets/english.svg'),
                portuguese:require('../assets/portuguese.svg'),
                french:require('../assets/french.svg'),
                
            }
        },
        methods: {
          toggleDropdown(){
            this.dropdownOpen=!this.dropdownOpen
          },

          selectLanguage(language) {
            this.selectedLanguage = language;
            this.$i18n.locale = this.selectedLanguage;
            this.dropdownOpen = false;
          },

          getLanguageCode(language) {
            if (language === 'en') {
              return 'EN';
            } else if (language === 'portuguese') {
              return 'PT';
            } else if (language === 'fr') {
              return 'FR';
            }
            return '';
          },
          getLanguageImage(language) {
            if (language === 'en') {
              return englishImage;
            } else if (language === 'portuguese') {
              return portugueseImage;
            } else if (language === 'fr') {
              return frenchImage;
            }
            return '';
          },
          Services(){
            this.showServices= !this.showServices
          },

        },

        computed:{
        isEng(){
            return this.$i18n.locale === 'en';
        },
        isFrench(){
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
      },

    }
</script>

<style>
.boxShadow{
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2);
}
</style>