<template>
    <div class="w-10/12 m-auto py-4">
        <NavBar/>
    </div>
    <div class="bg-[url('@/assets/drivingHand.png')] h-[500px] bg-cover">
        <div class=" lg:w-5/12 pl-20 pt-48 sm:w-1/2 lg:mx-0 lg:pl-32 ">
            <p class="text-white lg:text-5xl text-4xl font-bold  leading-tight pb-10">{{ $t('drive_earn') }}</p>
            <router-link to="/download-center" class="bg-blue-500 text-white rounded-3xl px-6 py-4 font-medium">{{ $t('get_app') }}</router-link>            
        </div>
    </div>

    <div class="pt-20 w-10/12 mx-auto pb-28">
        <div class="">
            <h1 class="text-center text-4xl text-gray-900 font-bold">{{ $t('how_works') }}</h1>

            <div class="flex lg:pt-28 lg:justify-between lg:flex-row lg:items-center flex-col pt-16 ">
                
                <img v-lazy="scheduleGuy" alt="" class=" lg:w-2/5 mx-auto w-full">
            
                <div class="lg:w-2/5 pt-10 lg:pt-0">
                    <span>
                        <img v-lazy="one" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('choose_schedule') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('choose_schedule_text') }}</p>
                    </span>

                </div>
            </div>

            <div class="flex lg:pt-28 lg:justify-between lg:flex-row lg:items-center flex-col-reverse pt-10 ">
                        
                <div class="lg:w-2/5  lg:pt-0 pt-10">
                    <span>
                        <img v-lazy="two" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('support_success') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('support_success_text') }}</p>
                    </span>

                </div>
                <img v-lazy="supportGuy" alt="" class="lg:w-2/5 mx-auto lg:mx-0 w-full lg:pt-0 ">

            </div>

            <div class="flex lg:pt-28 lg:justify-between lg:flex-row lg:items-center flex-col pt-10 ">
                
                <img v-lazy="competitiveGuy" alt="" class="w-full lg:w-2/5 mx-auto lg:mx-0">
            
                <div class="lg:w-2/5 pt-10 lg:pt-0">
                    <span>
                        <img v-lazy="three" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('competitive_rates') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('competitive_rates_text') }}</p>
                    </span>

                </div>
            </div>
        </div>

    </div>

    <downloadDrive/>
    <HomeFooter/>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import downloadDrive from '@/components/downloadDrive.vue';
import HomeFooter from '@/components/HomeFooter.vue';
export default{
  created () {
  },
    components:{
    NavBar,
    downloadDrive,
    HomeFooter
    },
    data(){
        return{
            scheduleGuy: require('@/assets/scheduleGuy.png'),
            one: require('@/assets/one.svg'),
            two: require('@/assets/two.svg'),
            three: require('@/assets/three.svg'),
            supportGuy: require('@/assets/supportGuy.png'),
            competitiveGuy: require('@/assets/competitiveGuy.png'),
        }
    }
}
</script>