<template>
    <div class="w-10/12 mx-auto">
        <NavBar/>

        <div class="flex lg:flex-col flex-col-reverse space-y-10">
            <div class="flex justify-between items-center lg:pt-20 ">
                <span class="lg:w-1/2 w-full">
                    <p class="sm:text-5xl text-3xl font-bold">{{ $t('affordable') }}</p>
                    <p class="text-gray-500 lg:pt-10 pt-5">{{ $t('about_us_text') }}</p>   
                </span>

                <span>
                    <img v-lazy="gabTop" alt="" class=" lg:block hidden">
                </span>
            </div>
            <img v-lazy="gabBottom" alt="" class="rounded-lg pb-10">
        </div>

        <p class="text-3xl font-bold lg:pt-0 pt-20 text-center pb-10">{{ $t('our_mission') }}</p>

        <div class="sm:w-9/12 w-10-12 flex  justify-between m-auto">
            <span class="flex flex-col space-y-2 items-center">
                <img v-lazy="safe" alt="" class="w-[68px] sm:w-auto">
                <p class="text-gray-700">{{ $t('safe_') }}</p>
            </span> 
            <span class="flex flex-col space-y-2 items-center">                
                <img v-lazy="affordable" alt="" class="w-[68px] sm:w-auto">
                <p class="text-gray-700">{{ $t('affordable') }}</p>
            </span>
            <span class="flex flex-col space-y-2 items-center">
                <img v-lazy="efficient" alt="" class="w-[68px] sm:w-auto">
                <p class="text-gray-700">{{ $t('efficient') }}</p>
            </span>           
        </div>

        <div class="flex lg:justify-between sm:pt-32 pt-10 lg:items-start lg:flex-row flex-col-reverse items-center">
            <div class="lg:w-[40%] ">
            <button class="bg-customBlue px-8 text-xl text-white rounded-2xl lg:pt-0 py-1 lg:mt-0 mt-10">{{ $t('safe_') }}</button>
            <p class="sm:text-4xl text-2xl font-semibold sm:leading-[50px] leading[30px] pt-5 ">{{$t('we_prioritize')}} <span class="text-customBlue">{{$t('Safety')}}</span> {{$t('above_all')}}</p>
            <p class="pt-5 text-gray-600 text-lg">{{$t('we_prioritize_text')}}</p>
        </div>

        <div class="">
            <img v-lazy="iPhone11" alt="" class="rounded-2xl lg:w-[460px]">
        </div>
        </div>
 
        <div class="flex lg:justify-between sm:pt-32 pt-10 lg:items-start lg:flex-row flex-col items-center">
            <div>
                <img v-lazy="affordab" alt="" class="rounded-2xl lg:h-auto sm:h-[560px] ">
            </div>
            <div class="lg:w-[40%] w-full lg:pt-0 pt-10 py-2 ">
            <button class="bg-customBlue px-12 text-xl text-white rounded-3xl py-2">{{$t('affordable')}}</button>
            <p class="sm:text-4xl text-2xl font-semibold sm:leading-[50px] leading[50px] pt-5">{{$t('there_are')}} <span class="text-customBlue">{{$t('no_hidden_fees')}}</span> {{$t('or')}} <span class="text-customBlue">{{$t('surprise_charges')}}</span></p>
            <p class="pt-5 text-gray-500">{{$t('surprise_text')}}</p>
            </div>
        </div>

        <div class="flex lg:justify-between sm:py-32 py-10 lg:items-start lg:flex-row flex-col-reverse items-center ">

            <div class="lg:w-[40%] w-full pt-10 lg:pt-0">
            <button class="bg-customBlue px-12 text-xl text-white rounded-3xl py-2"> {{$t('efficient')}}</button>
            <p class="sm:text-4xl text-2xl font-semibold sm:leading-[50px] pt-10">{{$t('exceptional_experience')}}<span class="text-customBlue">{{$t('for_you')}}</span></p>
            <p class="pt-5 text-gray-500">{{$t('exceptional_experience_text')}}</p>
            </div>

            <div>
                <img v-lazy="exceptional" alt="" class="rounded-2xl lg:h-auto sm:h-[560px]">
            </div>
        </div>
    </div>
    <downloadApps/>
<HomeFooter/>
    
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import HomeFooter from '@/components/HomeFooter.vue';
import downloadApps from '@/components/downloadApps.vue';
export default{
    components:{
        NavBar,
        HomeFooter,
        downloadApps
    },

    data() {
        return {
            Rides:true,
            gabTop:require('../assets/gabTop.png'),
            gabBottom: require('../assets/gabBottom.png'),
            safe:require('../assets/safe.svg'),
            affordable:require('../assets/affordable.svg'),
            efficient:require('../assets/efficient.svg'),
            iPhone11:require('../assets/iPhone11.png'),
            affordab:require('../assets/affordable.png'),
            exceptional:require('../assets/exceptional.png'),
        }
    },
    methods:{
showRides(){
    this.Rides = true
},
showDrive(){
    this.Rides = false
}
}
    }
</script>



