<template>
    <div class="pt-20 w-10/12 mx-auto pb-20">
        <div class="">
            <h1 class="text-center text-4xl text-gray-900 font-bold">{{ $t('need_ride') }}</h1>

            <div class="flex sm:pt-28 pt-10 lg:justify-between lg:flex-row flex-col-reverse items-start">
            
                <div class="lg:w-2/5 sm:w-4/5 w-11/12 lg:pt-0 pt-20 ">
                    <span>
                        <img v-lazy="one" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('efficient_transport') }} </p>
                        <p class="text-gray-600 w-full sm:w-10/12 pt-4">{{ $t('quick_booking') }}.</p>
                    </span>
                    <span>
                        <img v-lazy="two" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('simplify_commute') }}</p>
                        <p class="text-gray-600 w-full sm:w-10/12 pt-4">{{ $t('streamlined_booking') }}</p>
                    </span>
                    <span>
                        <img v-lazy="three" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('experience_future') }}</p>
                        <p class="text-gray-600 w-full sm:w-10/12 pt-4">{{ $t('intuitive') }}</p>
                    </span>
                </div>
                <img v-lazy="womanCalling" class="lg:w-2/5 w-4/5 lg:mx-0 mx-auto lg:h-[561px]" alt="">
                <img v-lazy="rideStatus1" alt="" class="absolute lg:right-0 lg:pt-20 lg:pr-96 lg:pb-0 lg:mb-0 mb-96 pb-96 hidden lg:top-104 top-120" :class="{'lg:block': isEng,'z-[50]':isEng, 'lg:hidden': isFrench, 'lg:hidden': isPort}">
                <img v-lazy="rideStatus1French" alt="" class="absolute lg:right-0 lg:pt-20 lg:pr-96 lg:pb-0 lg:mb-0 mb-96 pb-96 lg:top-104 top-120 hidden" :class="{'lg:hidden': isEng, 'lg:block': isFrench, 'lg:hidden': isPort}">
                <img v-lazy="rideStatus1Port" alt="" class="absolute lg:right-0 lg:pt-20 lg:pr-96 lg:pb-0 lg:mb-0 mb-96 pb-96 lg:top-104 top-120 hidden" :class="{'lg:hidden': isEng, 'lg:hidden': isFrench, 'lg:block': isPort}">

                <img v-lazy="rideStatus2" alt="" class="absolute right-0 sm:pt-80 lg:pb-0 pb-96 lg:mb-0 mb-96 lg:pl-0 pl-40 hidden" :class="{'lg:block': isEng, 'z-[50]':isEng, 'lg:hidden': isFrench, 'lg:hidden': isPort}">
                <img v-lazy="rideStatus2French" alt="" class="absolute right-0 sm:pt-80 lg:pb-0 pb-96 lg:mb-0 mb-96 lg:pl-0 pl-40 hidden" :class="{'lg:hidden': isEng, 'lg:block': isFrench, 'lg:hidden': isPort}">
                <img v-lazy="rideStatus2Port" alt="" class="absolute right-0 sm:pt-80 lg:pb-0 pb-96 lg:mb-0 mb-96 lg:pl-0 pl-40 hidden" :class="{'lg:hidden': isEng, 'lg:hidden': isFrench, 'lg:block': isPort}">
            </div>
        </div>

    </div>
</template>

<script>
export default{
    data(){
        return{
            one: require('@/assets/one.svg'),
            two: require('@/assets/two.svg'),
            three: require('@/assets/three.svg'),
            womanCalling: require('@/assets/womanCalling.png'),
            rideStatus1: require('@/assets/rideStatus1.svg'),
            rideStatus1Port: require('@/assets/rideStatus1Port.svg'),
            rideStatus1French: require('@/assets/rideStatus1French.svg'),
            
            rideStatus2: require('@/assets/rideStatus2.svg'),
            rideStatus2Port: require('@/assets/rideStatus2Port.svg'),
            rideStatus2French: require('@/assets/rideStatus2French.svg'),

        }
    },

    computed:{
        isFrench(){ 
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en';
        }
    }
}
</script>