<template>
    <div class="w-10/12 mx-auto">
        <NavBar/>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default{
    components:{
        NavBar
    }
}
</script>