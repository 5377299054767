<template>
    <div :class="{'bg-slate-50':showPassenger, 'bg-white':showDriver}">
        <p class="text-4xl font-bold text-gray-900 text-center lg:pt-16 pt-10 pb-10">{{ $t('how') }}</p>

        <!-- lg:w-1/6 sm:w-2/6 w-3/5 -->
        <span class="flex bg-slate-100  mx-auto py-2 justify-between  px-2 rounded-2xl lg:w-1/6 w-1/2 sm:w-1/3"
        :class="{'lg:w-[250px]':isFrench, 'sm:w-[260px]':isFrench , 'lg:w-[300px]':isPort, 'w-8/12':isFrench, 'w-3/5':isPort, 'w-[250px]': isEng }">
            <button class="text-black font-medium"
            :class="{'bg-white': showPassenger,'py-2':showPassenger,'px-6':showPassenger,'rounded-2xl':showPassenger,'pl-3':!showPassenger}" @click="passengerInfo"
            >{{ $t('passenger') }}</button>
            <button class="text-black font-medium" @click="driverInfo"
            :class="{'bg-white': showDriver,'py-2':showDriver,'px-6':showDriver,'rounded-2xl':showDriver,'pr-3':!showDriver}"
            >{{ $t('driver') }}</button>
        </span>
        <div class="">
            <div v-if="showPassenger">
                <PassengerInfo/>
            </div>
            <div v-else>
                <DriverInfo/>
            </div>            
        </div>

    </div>
</template>

<script>
import PassengerInfo from "@/components/PassengerInfo.vue"
import DriverInfo from "@/components/DriverInfo.vue"
    export default{
        components:{
            PassengerInfo,
            DriverInfo
        },
        data(){
            return{
                showPassenger: true,
                showDriver: false,
            }
        },

        methods:{
            passengerInfo(){
                this.showPassenger = true;
                this.showDriver = false; 
            },
            driverInfo(){
                this.showPassenger = false;
                this.showDriver = true; 
            }
        },
        computed:{
        isFrench(){
            return this.$i18n.locale === 'fr';
        },

        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en';
        }
    }
    }
</script>