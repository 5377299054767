<template>
    <div class="bg-blue-500">
        <div class="sm:w-10/12 m-auto flex justify-between lg:flex-row flex-col items-center lg:space-y-0 space-y-10 lg:pb-0 pb-10">
            <div>
                <p class="text-white lg:text-4xl text-2xl text-center sm:text-left font-bold  lg:pt-0 pt-16">{{ $t('download_gab_now') }}</p> 
        
                <div class="pt-10 flex gap-4 flex-row  lg:items-stretch items-center m-auto lg:m-0 w-full lg:justify-normal justify-center">
                    <button class="flex items-center gap-2 py-2 bg-black px-7 rounded-xl leading-tight ">
                    <i class="fa-brands fa-apple text-4xl text-white"></i>
                    <a href="https://apps.apple.com/ng/app/gab-driver-drive-to-earn/id1670609398" class="text-white">
                        <p class="text-sm text-gray-300 leading-tight">Download on</p>
                        <p class="text-lg font-medium">App Store</p>
                    </a>
                    </button>
                    <button class="flex items-center leading-tight gap-2 bg-black px-6 py-2 rounded-xl ">
                    <img v-lazy="img4" alt="" class="w-[25px]">
                    <a href="https://play.google.com/store/apps/details?id=com.gabtaxi.driver" class="leading-snug">
                        <p class="text-sm text-gray-300">Download on</p>
                        <p class="text-white text-lg font-medium">Play Store</p>
                    </a>
    
                    </button>
              </div>
            </div>
            
            <div>
                <img v-lazy="img5" alt="" :class="{'hidden': !isEng}" class="w-[400px]">
                <img v-lazy="portugueseDriver" alt="" :class="{'hidden': !isPort}" class="w-[400px]">
                <img v-lazy="frenchDriver" alt="" :class="{'hidden': !isFrench}" class="w-[400px]">                
            </div>


        </div>        
    </div>
</template>


<script>
export default{
    data(){
        return{
            img4:require('@/assets/gpLogo.svg'),
            img5: require('@/assets/downloadDriver.png'),
            frenchDriver: require('@/assets/frenchDriver.png'),
            portugueseDriver: require('@/assets/portugueseDriver.png'),
        }
    },
    computed:{
        isFrench(){
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en';
        },
        
      },
}
</script>