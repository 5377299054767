<template>
    <div class="w-10/12 m-auto py-4">
        <NavBar/>
    </div>
    <div class="bg-gray-50">
        <div class="w-11/12 m-auto flex justify-center gap-40 pt-20 sm:pb-20 pb-10 lg:flex-row items-center flex-col-reverse">
            <span class="lg:w-4/12">
                <p class="text-3xl font-bold">{{ $t('gab_logistics') }}</p>
                <p class="text-gray-600 pt-5">{{ $t('gab_logistics_text') }}</p>

                <button class="bg-blue-50 text-gray-600 rounded-3xl mt-10 px-4 py-3 font-medium">{{ $t('coming_soon') }}</button>
            </span>
            <div class="flex lg:w-1/3 ">
                <img v-lazy="logistics" alt="">
            </div>
        </div>
    </div>

    <!-- How it works -->
    <div class="pt-20 w-10/12 mx-auto pb-28">
        <div class="">
            <h1 class="text-center text-4xl text-gray-900 font-bold">{{ $t('how_works') }}</h1>

            <div class="flex lg:pt-28 lg:justify-between lg:flex-row lg:items-start flex-col pt-16 ">
                
                <img v-lazy="logisticsWoman" alt="" class="w-10/12 lg:w-2/5 mx-auto lg:mx-0">
            
                <div class="lg:w-2/5 pt-10 lg:pt-0">
                    <span>
                        <img v-lazy="one" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('register') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('download_passenger_app') }}</p>
                    </span>
                    <span>
                        <img v-lazy="two" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('select_dropoff') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('login_to_app') }}</p>
                    </span>
                    <span>
                        <img v-lazy="three" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('item_delivered') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('item_pickedUp') }}</p>
                    </span>
                </div>
            </div>
        </div>

    </div>

    <downloadRides/>
    <HomeFooter/>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import downloadRides from '@/components/downloadRides.vue';
import HomeFooter from '@/components/HomeFooter.vue';
    export default{
        components:{
            NavBar,
            downloadRides,
            HomeFooter
        },
        data(){
            return{
                logistics: require('../assets/logistics.png'),
                logisticsWoman: require('@/assets/logisticsWoman.png'),
                one: require('@/assets/one.svg'),
                two: require('@/assets/two.svg'),
                three: require('@/assets/three.svg'),

            }
        }
    }
</script>