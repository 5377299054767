<template>
    <!-- Responsiveness for larger screens -->

    <div class="bg-slate-50 w-9/12 mx-auto lg:justify-between hidden lg:flex lg:py-40 flex-col lg:flex-row ">
        
        <div class="w-1/6 flex flex-col space-y-20 " :class="{'space-y-16':isFrench}">
            <span class=" text-right">
                <div class="flex justify-end">
                    <img v-lazy="blueOne" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('request_trip') }}</p>             
                <p class="text-gray-700 text-sm"> {{ $t('select_pickup') }}</p>   
            </span>

            <span class=" text-right">
                <div class="flex justify-end">
                    <img v-lazy="blueThree" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('start_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('driver_meets_you') }}</p>   
            </span>

        </div>

        <div class="relative flex justify-center items-center lg:pt-0 pt-20">
            <img v-lazy="blueiPhone" :class="{'hidden': !isEng}" class="absolute h-[500px]">
            <img v-lazy="frenchiPhone" :class="{'hidden': !isFrench}" class="absolute h-[500px]">
            <img v-lazy="PortiPhone" :class="{'hidden': !isPort}" class="absolute h-[500px]">
            <img v-lazy="blueCircle" class="" alt="">
        </div>

        <div class="w-1/6 flex flex-col space-y-20">
            <span class=" text-left">
                <div class="flex justify-start">
                    <img v-lazy="blueTwo" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('match') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('gab_will_match_you') }}</p>   
            </span>

            <span class=" text-left">
                <div class="flex justify-start">
                    <img v-lazy="blueFour" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('end_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('pay_for_your_trip') }}</p>   
            </span>

        </div>


    </div>


    <!-- Responsiveness for medium screens -->
    <div class="bg-slate-50 w-9/12 mx-auto lg:hidden sm:flex hidden flex-col sm:py-0 py-10"> 
        
        <div class="relative flex justify-center items-center lg:pt-0 pt-20">
            <img v-lazy="blueiPhone" :class="{'hidden': !isEng}" class="absolute h-[500px]">
            <img v-lazy="frenchiPhone" :class="{'hidden': !isFrench}" class="absolute h-[500px]">
            <img v-lazy="PortiPhone" :class="{'hidden': !isPort}" class="absolute h-[500px]">
            <img v-lazy="blueCircle" class="" alt="">
        </div>

        <div class="flex pt-40 pb-20 justify-between">        
            <div class="w-2/5 flex flex-col space-y-12 " :class="{'space-y-20':isFrench, 'space-y-20':isPort}">
            <span class=" text-right">
                <div class="flex justify-end">
                    <img v-lazy="blueOne" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('request_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('select_pickup') }}</p>   
            </span>

            <span class=" text-right">
                <div class="flex justify-end">
                    <img v-lazy="blueThree" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('start_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('driver_meets_you') }}</p>   
            </span>

            </div>
            <div class="w-2/5 flex flex-col space-y-12">
            <span class=" text-left">
                <div class="flex justify-start">
                    <img v-lazy="blueTwo" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('match') }}</p>             
                <p class="text-gray-700 text-sm"> {{ $t('gab_will_match_you') }}</p>   
            </span>

            <span class=" text-left pt-1">
                <div class="flex justify-start">
                    <img v-lazy="blueFour" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('end_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('pay_for_your_trip') }}</p>   
            </span>

            </div>
        </div>

    </div>

                <!-- Responsiveness for small screens -->
    <div class="bg-slate-50 w-9/12 mx-auto sm:hidden flex flex-col">
        
        <div class="relative flex justify-center items-center pt-40">
            <img v-lazy="blueiPhone" :class="{'hidden': !isEng}" class="absolute h-[400px]">
            <img v-lazy="frenchiPhone" :class="{'hidden': !isFrench}" class="absolute h-[400px]">
            <img v-lazy="PortiPhone" :class="{'hidden': !isPort}" class="absolute h-[400px]">
            <img v-lazy="blueCircle" class="" alt="">
        </div>

        <div class="pt-40 pb-20 flex flex-col space-y-20 items-center">        
            <div class="w-2/3 flex flex-col space-y-20">
            <span class=" text-center">
                <div class="flex justify-center">
                    <img v-lazy="blueOne" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('request_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('select_pickup') }}</p>   
            </span>
            <span class=" text-center">
                <div class="flex justify-center">
                    <img v-lazy="blueTwo" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('match') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('gab_will_match_you') }}</p>   
            </span>


            </div>

            <div class="w-2/3 flex flex-col space-y-20">
            <span class=" text-center">
                <div class="flex justify-center">
                    <img v-lazy="blueThree" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('start_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('driver_meets_you') }}</p>   
            </span>

            <span class=" text-center">
                <div class="flex justify-center">
                    <img v-lazy="blueFour" class="" alt="">                    
                </div>

                <p class="text-xl font-bold text-gray-900 py-2">{{ $t('end_trip') }}</p>             
                <p class="text-gray-700 text-sm">{{ $t('pay_for_your_trip') }}</p>   
            </span>

            </div>
        </div>
        </div>
</template>

<script>
    export default{
        data(){
            return{
                blueOne: require('@/assets/blueOne.svg'),
                blueTwo: require('@/assets/blueTwo.svg'),
                blueThree: require('@/assets/blueThree.svg'),
                blueFour: require('@/assets/blueFour.svg'),
                iPhone: require('@/assets/iPhone.svg'),
                blueiPhone: require('@/assets/blueiPhone.png'),
                frenchiPhone: require('@/assets/frenchDownloadiPhone.png'),
                PortiPhone: require('@/assets/portDownloadiPhone.png'),
                blueCircle: require('@/assets/blueCircle.svg')

            }
        },
        computed:{
        isFrench(){
            return this.$i18n.locale === 'fr';
        },

        isEng(){
            return this.$i18n.locale === 'en';
        },

        isPort(){
            return this.$i18n.locale === 'portuguese';
        }
    }

    }
</script>