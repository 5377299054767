const messages={
    // NavBar
    home:'Maison',
    about_us: 'À propos de nous',
    blog:'Blogue',
    services:'Prestations de service',
    contact:'Contactez-nous',
    eng:'Anglaise',
    fren:'Français',
    port:'Portugais',
    become_driver:'Devenez chauffeur',
    rides:'Monte',
    ride_driver:'Conducteur',
    logistics:'Logistique',
    emergency:"Services d'urgence",
 
    // Hero Section
    safe:'Balade sûre et abordable.',
    thousands:"Des milliers de personnes, d'organisations et d'entreprises dépendent de nos services de transport pour se déplacer dans la ville.",
    request:'DEMANDEZ UN VOYAGE MAINTENANT',

    // Request a ride view
    ride_with_ease:'Roulez avec facilité, grand confort, sécurité et arrivez en toute confiance.',
    request_ride: 'Demander maintenant',

    // Drive and Earn
    drive:'Conduisez et gagnez',
    find:'Trouvez des voyages qui correspondent à un horaire flexible',
    plan:'Vous pouvez conduire aussi longtemps et aussi souvent que vous le souhaitez. Les jours de semaine, les week-ends et les soirées - planifiez votre conduite en fonction de votre emploi du temps.',
    earn:'Gagnez plus en conduisant avec Gab',
    get_numerous_rides:'Recevez de nombreuses demandes de trajet et gagnez plus chaque jour.',
    earn_money_everyweek:"Gagner de l'argent chaque semaine",
    drive_with_gab:"Vous voulez gagner de l'argent avant le jour de paie? Conduisez avec Gab maintenant.",

    // How Gab works
    how:'Comment Gab fonctionne',
    passenger:'Passagèrer',
    driver:'Conducteur',

    // Passenger Info and Driver Info
    request_trip:'Demander un voyage',
    select_pickup:'Sélectionnez votre prise en charge et votre destination, puis sélectionnez votre option de trajet.',
    start_trip: 'Commencer le voyage',
    driver_meets_you: "Le chauffeur vous rencontre à l'aide de notre service GPS en temps réel et commence le voyage.",
    match:'Correspondance avec le chauffeur',
    gab_will_match_you: 'Gab vous mettra en relation avec le prochain chauffeur disponible qui vous conduira',
    end_trip:'Terminer le voyage et payer',
    pay_for_your_trip:'Payez votre trajet en espèces ou par carte, et tarifez le chauffeur. Bon voyage avec Gab.',
    pay_and_give_ratings:'Payer et donner des notes',
    pay_description: 'Payez votre voyage en espèces ou par carte, et votre chauffeur',

    // Need a Ride?  
    need_ride:"Besoin d'un tour? Nous vous Gab.",
    efficient_transport:'Transport efficace et fiable',
    quick_booking:'Processus de réservation rapide et facile pour un transport instantané avec un service 24 heures sur 24 pour répondre à vos besoins de transport à tout moment.',
    simplify_commute:'Simplifiez vos trajets avec Gab',
    streamlined_booking: 'Système de réservation simplifié pour une expérience sans stress. Suivez votre trajet en temps réel pour plus de sécurité et de commodité.',
    experience_future:"Découvrez l'avenir du VTC",
    intuitive:"Conception d'application intuitive et conviviale pour une navigation sans effort. Mesures de sécurité renforcées pour assurer une conduite en toute sécurité.",

    // Blog and News
    blog_and_news:'Blogue et nouvelles',
    see_all: 'Voir tout',
    prioritize_safety: "Il est important que les passagers accordent la priorité à leur sécurité lorsqu'ils utilisent le service...",
    platforms_revolutionized: 'Dans le monde en évolution rapide des transports, les plates-formes de messagerie électronique ont révolutionné...',
    launch_hailing_operations: 'E.F. Network Limited lance ses opérations de messagerie électronique dans le pays alors que...',

    // Download Gab Now
    download_gab: "Téléchargez l'application Gab maintenant",
    sign_up_to_drive:'Inscrivez-vous pour conduire',
    sign_up_to_ride:'Inscrivez-vous pour rouler',

    // Home Footer
    company:'Entreprise',
    aboutUs:'À propos de nous',
    gab_blog:'GabBlog',
    support_center:'Centre de soutien',
    legal:'Juridique',
    terms:'Termes et conditions',
    privacy_policy:'Politique de confidentialité',
    copyright:'Copyright 2023 Gab Taxi. Tous les droits sont réservés.',
    address :'Hala Hotel, Bisau, Guinea Bisau, V96H+55',
    address1 :'Hala Hotel, Bisau',
    address2 :'Guinea Bisau, V96H+55',
    oops:"Oups, pas encore d'article de blog...",

    // Services Rides
    got_you_covered:"Besoin d'un tour? Nous avons ce qu'il vous faut",
    get_app:"Obtenir l'application",

    // Rides Options
    ride_options:'Options de trajet',
    standard:'Standard',
    standard_text:"Tout comme votre taxi bleu et blanc habituel, l'option chute standard est économique et vous emmène à l'endroit souhaité sans vous ruiner.\n\n Bien que chute standard n'offre pas les caractéristiques luxueuses d'autres options de transport, telles que le vol conditionné, il offre un trajet confortable et sûr jusqu'à votre destination. Les chauffeurs sont contrôlés et formés pour garantir un haut niveau de professionnalisme, et les véhicules sont régulièrement entretenus pour garantir leur fiabilité.",
    get_started:'Get Started',
    comfort:'Confort',
    comfort_text:"L'une des principales caractéristiques de Gab est son option de confort, qui offre aux passagers un voyage confortable à un prix abordable. Les véhicules utilisés pour l'option confort sont bien entretenus, propres et équipés de sièges confortables et de la climatisation, garantissant aux passagers une conduite agréable et confortable.",
    vip:'VIP',
    vip_text:"Pour les passagers qui souhaitent voyager avec style, Gab propose une option VIP. Cette option est parfaite pour ceux qui veulent arriver à destination dans le luxe et le confort. Les véhicules utilisés pour l'option VIP sont des voitures haut de gamme conçues pour offrir une expérience luxueuse. Ils sont équipés d'équipements haut de gamme tels que des sièges en cuir, la climatisation et des systèmes de divertissement, garantissant aux passagers une expérience de première classe.",
    tricycle:'Tricycle',
    tricycle_text:"Nous avons fourni une option de tricycle pour les utilisateurs du village, où les taxis ne sont pas disponibles et aussi, où les tricycles ne sont pas autorisés dans les cantons. Les expériences utilisateur positives que nous proposons sont sans égal dans l'industrie, car nous avons pris en compte les besoins de chaque utilisateur de taxi.",

    // Service Drivers
    drive_earn:'Conduisez et gagnez selon vos propres conditions',
    how_works:'Comment ça fonctionne',
    choose_schedule:'Choisissez votre propre horaire',
    choose_schedule_text:"Nous comprenons que la flexibilité est importante, et c'est pourquoi nous offrons une opportunité unique aux chauffeurs de travailler selon leur propre horaire. \n\nEn tant que chauffeur Gab Taxi, vous avez la liberté de choisir quand, où et à quelle fréquence vous travaillez. Que vous souhaitiez faire de la conduite votre travail à temps plein ou simplement gagner un revenu supplémentaire, nous vous offrons la flexibilité de répondre à vos besoins.",
    support_success: 'Accompagnement et réussite',
    support_success_text:"Nous pensons que nos chauffeurs sont l'épine dorsale de notre entreprise et nous nous engageons à leur fournir le soutien dont ils ont besoin pour réussir. Nous offrons une formation continue et des ressources pour aider nos chauffeurs à améliorer leurs compétences et à développer leur entreprise.\n\n Chez Gab Taxi, nous sommes fiers de notre engagement envers la sécurité. Nous sélectionnons soigneusement tous nos chauffeurs pour nous assurer qu'ils ont un bon dossier de conduite et qu'ils connaissent bien les lois et règlements de la circulation. De plus, nous fournissons à nos chauffeurs des conseils et des meilleures pratiques pour rester en sécurité sur la route.",
    competitive_rates:'Des tarifs compétitifs',
    competitive_rates_text:"Nous proposons également des tarifs compétitifs et des incitations pour aider nos chauffeurs à maximiser leurs revenus. Avec Gab Taxi, vous pouvez gagner de l'argent selon vos propres conditions, tout en profitant des avantages de faire partie d'une entreprise dynamique et en pleine croissance. \n\nRejoignez l'équipe Gab Taxi dès aujourd'hui et commencez à conduire selon vos propres conditions.",

    // Download Drive
    download_gab_now:'Téléchargez le pilote Gab maintenant',

    // Emergency Services
    emergency_services:"Services d'urgence",
    emergency_services_text:"Les services d'urgence de Gab Taxi permettent aux utilisateurs de réserver une ambulance, des dépanneuses, d'appeler la hotline de sécurité de la police, la gestion des déchets et les services d'incendie en cas d'urgence. Gab offre aux utilisateurs un moyen rapide et facile d'accéder aux services d'urgence à portée de main. Avec Gab Taxi, les utilisateurs peuvent demander une ambulance pour les urgences médicales, des dépanneuses en cas de panne ou d'accident de voiture, appeler la hotline de sécurité de la police pour les menaces à la sécurité et les pompiers en cas d'incendie.",
    coming_soon:'À venir',
    register:'Enregistrer',
    download_passenger_app:"Téléchargez l'application Gab Passenger et enregistrez-vous en tant qu'utilisateur.",
    set_location:"Définissez votre emplacement d'urgence",
    make_request: 'Sélectionnez votre emplacement et faites votre demande',
    emergency_services_respond:"Les services d'urgence interviennent",
    cater_to_emergencies:'Les services qui répondent aux urgences répondent et viennent à votre emplacement.',

    // Gab Logistics
    gab_logistics:'Gab Logistique',
    gab_logistics_text:"Nous offrons au grand public la possibilité d'avoir leurs propres messagers personnels, qui feront leurs courses pour eux sans vivre dans le confort de leurs bureaux ou de leurs maisons. Les chauffeurs de Gab font également des livraisons et des ramassages.",
    select_dropoff:'Définir le ramassage et le retour',
    login_to_app:"Connectez-vous à l'application, sélectionnez la logistique et choisissez votre adresse de ramassage et de dépôt.",
    item_delivered:'Votre article est livré',
    item_pickedUp:'Votre article est ramassé et livré à votre porte.',

    // Gab Blog
    safe_lifestyle:'Le mode de vie sûr et abordable pour vous.',
    download_app:'Download our Apps',
    categories:'Catégories',
    news:'Nouvelles', 
    safety:'Sécurité',
    places:'Lieux',
    tips:'Conseils',
    search:'Recherche',
    recent:'Articles de blog récents',
    rise_of_ehailing:"L'essor des plates-formes de messagerie électronique telles que les services de covoiturage et de livraison de nourriture a révolutionné la façon dont les gens se déplacent et accèdent à divers biens et services.",
    gab_comfort:'Comment Gab vous apporte du confort',
    safety_for_public:'Sécurité pour le public ?',
    bustling_metropolis:'Abuja, la capitale du Nigeria, est une métropole animée qui offre de nombreuses opportunités.',
    blog_post:'Tous les articles du blog',
    advent_of_technology:"L'avènement de la technologie a révolutionné divers secteurs.",
    learn_more:'Apprendre encore plus',
    organize_digital:'Organisez vos actifs numériques avec une nouvelle méthodologie et là.',
    gab_owned:'Gab appartient à E.F. Network Limited et EFGroup.',
    first_indigenous:"La première application de taxi Gab indigène d'appel en ligne",
    ehailing_services:'Les services de messagerie électronique sont devenus de plus en plus populaires maintenant.',
    giant_industry:"Un géant de l'industrie Hi-tech et un fils du terroir a une entreprise.",

    // Contact Us
    contact_us: 'Contactez-nous',
    questions:'Questions ou commentaires?',
    get_in_touch:'Entrer en contact.',
    reach_out:'Contactez-nous!',
    your_email:'Votre E-mail',
    your_name:'Votre Nom',
    your_number:'Votre numéro de téléphone',
    your_message:'Votre message',
    email_support:'Assistance par e-mail à',
    call_us:'Appelez-nous au',

        // About Us
        affordable:'Abordable',
        about_us_text:"Bienvenue sur Gab Taxi, la première application de messagerie électronique qui vous connecte avec des options de transport fiables et abordables. Chez Gab Taxi, nous nous engageons à fournir à nos clients des trajets sûrs, pratiques et sans tracas, que vous ayez besoin de vous rendre au travail, de faire des courses ou simplement d'explorer votre ville.",
        safe_:'Sûr',
        efficient:'Efficace',
        our_mission:'Notre mission',
        we_prioritize: 'Nous privilégions',
        Safety:'sécurité',
        above_all:'par-dessus tout',
        we_prioritize_text:"Chez Gab Taxi, nous sélectionnons soigneusement tous nos chauffeurs pour nous assurer qu'ils ont un bon dossier de conduite et qu'ils connaissent bien les lois et règlements de la circulation. De plus, nous fournissons à nos chauffeurs une formation et un soutien continus pour nous assurer qu'ils sont équipés pour faire face à toute situation qui pourrait survenir.",
        there_are:'Il y a ',
        no_hidden_fees:'pas de frais cachés',
        or:' ou ',
        surprise_charges: 'frais surprises',
        surprise_text:"Nous croyons que la transparence est essentielle pour établir la confiance avec nos clients. C'est pourquoi nous proposons des tarifs initiaux, afin que vous sachiez exactement combien coûtera votre trajet avant même de le réserver. Nous acceptons une variété de méthodes de paiement, y compris les cartes de crédit, les cartes de débit et les espèces, ce qui vous permet de payer facilement votre course de la manière qui vous convient le mieux.",
        exceptional_experience:'Expérience exceptionnelle juste ',
        for_you:'pour toi ',
        exceptional_experience_text:"Notre équipe se consacre à créer une expérience exceptionnelle pour nos utilisateurs, du moment où vous réservez votre trajet jusqu'au moment où vous arrivez à destination. Nous comprenons l'importance de la ponctualité, c'est pourquoi nous travaillons dur pour nous assurer que nos chauffeurs sont rapides et fiables, arrivant à votre emplacement en temps opportun.",

        // Privacy Policy
        privacy_policy_text:"GabTaxi s'engage à protéger la vie privée de ses chauffeurs et passagers et à assurer la sécurité de leurs informations personnelles. Cet avis de confidentialité décrit comment GabTaxi traite et gère les données personnelles conformément aux lois et réglementations sur la protection des données.",
        personal_data:'1. Données personnelles que nous traitons',
        personal_data_text:'GabTaxi traite les données personnelles suivantes :',
        contact_details:'Coordonnées : nom, numéro de téléphone et adresse e-mail.',
        profile_info:'Informations de profil : photo de profil, adresses enregistrées, langue et préférences de communication.',
        geolocation:'Géolocalisation : lieu de prise en charge et de dépose, déroulement du trajet et destination finale du trajet.',
        payment_info:'Informations de paiement : le montant débité et la carte de paiement utilisée.',
        communication_and_correspondence : 'Enregistrements de communication et de correspondance : historique des discussions avec les chauffeurs ou les agents du service client.',
        limited_identification:"Données d'identification limitées de l'appareil : adresse IP sur laquelle l'application GabTaxi est installée.",
        data_about_usage:"Données sur l'utilisation des services de transport : statut du voyage, horaires et comportement des passagers.",
        data_related:"Données relatives à la communication par messages instantanés directement dans l'application GabTaxi : date et heure de la communication et son contenu.",
        other_user_generated_content:'Autre contenu généré par les utilisateurs : photos de véhicules en stationnement.',
        purpose_of_processing:'2.   Finalité du traitement',
        gab_processes:'GabTaxi traite les données personnelles aux fins suivantes :',
        provide_transport:'Fournir des services de transport : mettre les passagers en contact avec les chauffeurs, montrer les véhicules disponibles et faciliter une prise en charge et une dépose efficaces.',
        smooth_running:"Assurer le bon fonctionnement des services de transport : s'assurer que les passagers arrivent à destination, facturer correctement et entretenir la flotte.",
        optimize_app:"Pour optimiser l'application : pour informer les passagers des mises à jour, pour connecter les passagers aux services Internet, mobiles et téléphoniques, et pour protéger les comptes grâce à des contrôles d'authentification et de vérification.",
        improve_service:"Améliorer le service : analyser la couverture géographique, recommander des itinéraires efficaces et s'assurer que les véhicules se trouvent aux endroits les plus pratiques pour les clients.",
        process_payment:"Pour traiter le paiement : pour traiter les détails de paiement pour le covoiturage au nom des chauffeurs et pour les véhicules de location de GabTaxi. Maintenir et promouvoir les normes : recueillir les commentaires, résoudre les litiges et assurer la sécurité des utilisateurs et le respect des conditions générales.",
        communicate_with_passengers:'Pour communiquer avec les passagers : pour envoyer des bordereaux de confirmation de voyage, des reçus et des mises à jour de service.',
        legal_basis:'3.    Base légale',
        gab_processes_on_legal_basis:'GabTaxi traite les données personnelles sur la base légale de :',
        contract_to_provide:"Contrat : pour fournir les services contractés via l'application GabTaxi.",
        legitimate_interests:"Intérêts légitimes : optimiser l'application, améliorer le service, maintenir et promouvoir les normes et communiquer avec les passagers.",
        legal_obligations:"Obligations légales : pour se conformer aux obligations légales telles que les exigences fiscales et comptables.",
        consent:'Consentement : pour traiter les données personnelles à certaines fins pour lesquelles le consentement a été donné.',
        data_subject:'4. Droits des personnes concernées',
        drivers_and_passengers:'Les conducteurs et les passagers ont les droits suivants concernant leurs données personnelles :',
        right_to_access:"Droit d'accès : pour demander l'accès aux données personnelles détenues par GabTaxi.",
        right_to_rectification:'Right to rectification: To request that personal data be restricted',
        right_to_erasure:"Droit à l'effacement : demander l'effacement des données personnelles.",
        right_to_restrict:"Droit de limiter le traitement : demander la limitation du traitement des données personnelles.", 
        right_to_object:"Droit d'opposition : s'opposer au traitement des données personnelles à certaines fins",
        right_to_data:'Droit à la portabilité des données : demander une copie des données personnelles dans un format structuré et lisible par machine.',
        right_to_withdraw_consent:'Droit de retirer son consentement : retirer son consentement lorsque les données personnelles sont traitées sur la base du consentement.',
        data_protection:'5. Protection des données',
        gab_committed_to_security:"GabTaxi s'engage à assurer la sécurité des données personnelles et a mis en place des mesures techniques et organisationnelles pour empêcher l'accès, l'utilisation ou la divulgation non autorisés des données personnelles.",
        data_retention:'6. Conservation des données',
        gab_retains:"GabTaxi conserve les données personnelles aussi longtemps que nécessaire pour fournir les services de transport et aussi longtemps que la loi l'exige.",
        contact_us_:'7.   Contactez-nous',
        any_questions:'Si vous avez des questions ou des préoccupations concernant la manière dont GabTaxi traite les données personnelles, veuillez contacter notre responsable de la protection des données à',
        personalDataWeProcess:'Données personnelles que nous traitons',
        purposeOfProcessing:'Finalité du traitement',
        legalBasis:'Base Légale',
        dataSubject:'Droits des personnes concernées',
        dataProtection:'Protection des données',
        dataRetention:'La conservation des données',
        contactUs:'Contactez-nous',
        submit:'Soumettre',
    
}

export default messages;