<template>
    <div class="xl:w-8/12 sm:w-10/12 w-11/12 mx-auto">
        <NavBar/>
        <div class="flex lg:flex-row flex-col-reverse justify-between items-center pt-10">
            <div class="lg:w-1/3">
                <p class="lg:font-medium font-bold lg:pt-0 pt-10 sm:text-5xl text-3xl text-gray-900">{{ $t('safe') }}</p>
                <p class="text-gray-500 pt-10"> {{ $t('ride_with_ease') }}</p>
                <div class="py-10">
                    <form class="flex flex-col space-y-8 ">
                        <div class="w-full flex flex-col space-y-3">                        
                            <input type="text" placeholder="Enter pickup location" class="bg-slate-50 text-gray-400 rounded-sm py-2 px-10 w-full ">
                            <img v-lazy="location" class="absolute lg:left-32 sm:left-28 left-8" alt="">
                            <img v-lazy="rectangle" class="absolute lg:left-32 left-8 sm:left-28 pl-1 pt-2" alt="">
                            <input type="text" placeholder="Enter destination" class="bg-slate-50 text-gray-400 rounded-sm py-2 mb-10 px-10 w-full">
                            <img v-lazy="destination" class="absolute lg:bottom-0 lg:left-32 lg:top-96 lg:pt-40 pt-12 sm:left-28 left-8" alt="">

                            
                        </div>

                        <button class="bg-blue-500  font-medium text-white py-3 lg:px-3 rounded-3xl flex items-center gap-3 lg:w-1/2 sm:w-1/3 w-1/2 justify-center"
                        :class="{'w-full':isFrench, 'sm:w-1/2': isFrench}">{{ $t('request_ride') }}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg></button>
                    </form>
                </div>
            </div>
            
                <div class="bg-slate-50 sm:w-1/2 w-full sm:px-0  flex justify-center sm:py-10 rounded-xl sm:h-auto h-[410px]">
                <img v-lazy="requestiPhone" class="h-[650px] sm:w-auto w-1/2 pb-60 sm:pb-0" alt="">
            
                <img v-lazy="tripStarted" class="absolute sm:pt-96 pt-40 top-72 sm:pl-72 pl-40" alt="" :class="{'block' : isEng, 'hidden': !isEng}">
                <img v-lazy="tripStartedFrench" class="absolute sm:pt-96 pt-40 top-72 sm:pl-72 pl-40" alt="" :class="{'block' : isFrench, 'hidden': !isFrench}">
                <img v-lazy="tripStartedPort" class="absolute sm:pt-96 pt-40 top-72 sm:pl-72 pl-40" alt="" :class="{'block' : isPort, 'hidden': !isPort}">


                <img v-lazy="driverArrived" class="absolute sm:right-60 sm:top-52 top-40 left-10 lg:pl-96 lg:left-60 sm:h-auto h-[70px]" alt="" :class="{'block' : isEng, 'hidden': !isEng}">
                <img v-lazy="driverArrivedFrench" class="absolute sm:right-60 sm:top-52 top-40 left-10 lg:pl-96 lg:left-60 sm:h-auto h-[70px]" alt="" :class="{'block' : isFrench, 'hidden': !isFrench}">
                <img v-lazy="driverArrivedPort" class="absolute sm:right-60 sm:top-52 top-40 left-10 lg:pl-96 lg:left-60 sm:h-auto h-[70px]" alt="" :class="{'block' : isPort, 'hidden': !isPort}">
                <img v-lazy="scalar2" class="absolute pl-96" alt="">
                <img v-lazy="multicolorScalar" class="absolute top-96 sm:pt-60 pr-96" alt="">
                <img v-lazy="ladyImg" class="absolute top-40 sm:h-auto sm:w-auto w-[300px] pt-60 sm:pr-72 pr-40 sm:top-60 sm:pt-96" alt="">
                
                </div>
            
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default{
    components:{
        NavBar
    },
    data(){
        return{
            location: require('@/assets/location.svg'),
            rectangle: require('@/assets/rectangle.svg'),
            destination: require('@/assets/destination.svg'),
            requestiPhone: require('@/assets/requestiPhone.svg'),
            tripStarted: require('@/assets/tripStarted.svg'),
            tripStartedFrench: require('@/assets/tripStartedFrench.svg'),
            tripStartedPort: require('@/assets/tripStartedPort.svg'),
            driverArrived: require('@/assets/driverArrived.svg'),
            driverArrivedFrench:require('@/assets/driverArrivedFrench.svg'),
            driverArrivedPort:require('@/assets/driverArrivedPort.svg'),
            scalar2:require('@/assets/scalar2.svg'),
            multicolorScalar:require('@/assets/multicolorScalar.svg'),
            ladyImg:require('@/assets/ladyImg.svg'),

        }
    },
    computed:{
        isEng(){
            return this.$i18n.locale === 'en';
        },
        isFrench(){
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        }

    }
}
</script>