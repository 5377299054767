<template>
    <!-- Media responsiveness for larger devices -->
    <div class="lg:block hidden">
    <div class="w-10/12 mx-auto ">
        <NavBar/>
    </div>
        <div class="flex justify-between items-center pt-20 pl-36">
            <div class="w-1/3">
                <p class="text-5xl font-bold leading-[60px]">Download the Passenger App</p>
                <div class=" lg:pt-10 pt-20 ">
                    <span>
                        <img v-lazy="one" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('efficient_transport') }} </p>
                        <p class="text-gray-600 w-full sm:w-10/12 pt-4">{{ $t('quick_booking') }}.</p>
                    </span>
                    <span>
                        <img v-lazy="two" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('simplify_commute') }}</p>
                        <p class="text-gray-600 pt-4">{{ $t('streamlined_booking') }}</p>
                    </span>
                    <span>
                        <img v-lazy="three" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('experience_future') }}</p>
                        <p class="text-gray-600  pt-4">{{ $t('intuitive') }}</p>
                    </span>
                </div>
                <div class="pt-10 flex gap-4 flex-row  lg:items-stretch items-center m-auto lg:m-0 w-full lg:justify-normal justify-center">
                    <button class="flex items-center gap-2 py-2 bg-black px-7 rounded-xl leading-tight ">
                    <i class="fa-brands fa-apple text-4xl text-white"></i>
                    <a href="https://apps.apple.com/ng/app/gab-safe-and-affordable/id1670627457" class="text-white">
                        <p class="text-sm text-gray-300 leading-tight">Download on</p>
                        <p class="text-lg font-medium">App Store</p>
                    </a>
                    </button>
                    <button class="flex items-center leading-tight gap-2 bg-black px-6 py-2 rounded-xl ">
                    <img v-lazy="gpLogo" alt="" class="w-[25px]">
                    <a href="https://play.google.com/store/apps/details?id=com.gabtaxi.gabtaxi_users_app" class="leading-snug">
                        <p class="text-sm text-gray-300">Download on</p>
                        <p class="text-white text-lg font-medium">Play Store</p>
                    </a>
    
                    </button>
              </div>
            </div>
            
                <img v-lazy="downloadCenter" class="h-[900px]" alt="">
                <img v-lazy="ridePic" class="absolute top-92 right-0 pr-72 h-[600px]" alt="" :class="{'hidden': !isEng}">
                <img v-lazy="ridePicFrench" class="absolute top-92 right-0 pr-72 h-[600px]" alt="" :class="{'hidden': !isFrench}">
                <img v-lazy="ridePicPort" class="absolute top-92 right-0 pr-72 h-[600px]" alt="" :class="{'hidden': !isPort}">
            
        </div>

        <div class="pt-20 flex item-center justify-between pr-36">
                 <img v-lazy="drivePic" class="pt-40 pl-80 absolute h-[800px]" alt="" :class="{'hidden': !isEng}">
                 <img v-lazy="drivePicFrench" class="pt-40 pl-80 absolute h-[800px]" alt="" :class="{'hidden': !isFrench}">
                 <img v-lazy="drivePicPort" class="pt-40 pl-80 absolute h-[800px]" alt="" :class="{'hidden': !isPort}">
                <img v-lazy="blackDownloadCenter" class="h-[960px]" alt="">
               
            

            <div class="flex flex-col w-1/3">       
                <p class="text-5xl font-bold leading-[60px] ">Download the Driver App</p>  

                 <div class="">
                    <span>
                        <img v-lazy="one" alt="" class="pt-10">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('find') }} </p>
                        <p class="text-gray-600 w-full">{{ $t('plan') }}.</p>
                    </span>
                    <span>
                        <img v-lazy="two" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('earn') }}</p>
                        <p class="text-gray-600 pt-4 w-full">{{ $t('get_numerous_rides') }}</p>
                    </span>
                    <span>
                        <img v-lazy="three" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('earn_money_everyweek') }}</p>
                        <p class="text-gray-600 w-full pt-4">{{ $t('drive_with_gab') }}</p>
                    </span>
                </div> 

                <div class="flex gap-5 pt-10">
                    <button class="flex items-center gap-2 py-2 bg-black px-7 rounded-xl leading-tight ">
                    <i class="fa-brands fa-apple text-4xl text-white"></i>
                    <a href="https://apps.apple.com/ng/app/gab-driver-drive-to-earn/id1670609398" class="text-white">
                        <p class="text-sm text-gray-300 leading-tight">Download on</p>
                        <p class="text-lg font-medium">App Store</p>
                    </a>
                    </button>
                    <button class="flex items-center leading-tight gap-2 bg-black px-6 py-2 rounded-xl ">
                    <img v-lazy="gpLogo" alt="" class="w-[25px]">
                    <a href="https://play.google.com/store/apps/details?id=com.gabtaxi.driver" class="leading-snug">
                        <p class="text-sm text-gray-300">Download on</p>
                        <p class="text-white text-lg font-medium">Play Store</p>
                    </a>
    
                    </button>
              </div>
            </div>

        </div>
    
    </div>
    <!-- Media responsiveness for smaller screens -->

    <div class="w-10/12 mx-auto lg:hidden block">
        <NavBar/>
        </div>
        <div class="pt-20 lg:hidden block">
            <div class="sm:pl-20 pl-10 lg:pl-0">
                <p class="sm:text-3xl text-2xl lg:w-full lg:mx-auto font-bold leading-[40px] ">Download the Passenger App</p>
                <div class="lg:w-8/12 sm:w-4/5 w-11/12 lg:pt-10 pt-20 ">
                    <span>
                        <img v-lazy="one" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('efficient_transport') }} </p>
                        <p class="text-gray-600 w-full sm:w-10/12 pt-4">{{ $t('quick_booking') }}.</p>
                    </span>
                    <span>
                        <img v-lazy="two" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('simplify_commute') }}</p>
                        <p class="text-gray-600 w-full sm:w-10/12 pt-4">{{ $t('streamlined_booking') }}</p>
                    </span>
                    <span>
                        <img v-lazy="three" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('experience_future') }}</p>
                        <p class="text-gray-600 w-full sm:w-10/12 pt-4">{{ $t('intuitive') }}</p>
                    </span>
                </div>

            </div>
            <div class="pt-10 flex gap-4 flex-row justify-center sm:justify-normal lg:w-full sm:pl-20 pl-0">
                    <button class="flex items-center gap-2 py-2 bg-black px-7 rounded-xl leading-tight ">
                    <i class="fa-brands fa-apple text-4xl text-white"></i>
                    <a href="https://apps.apple.com/ng/app/gab-safe-and-affordable/id1670627457" class="text-white">
                        <p class="text-sm text-gray-300 leading-tight">Download on</p>
                        <p class="text-lg font-medium">App Store</p>
                    </a>
                    </button>
                    <button class="flex items-center leading-tight gap-2 bg-black px-6 py-2 rounded-xl ">
                    <img v-lazy="gpLogo" alt="" class="w-[25px]">
                    <a href="https://play.google.com/store/apps/details?id=com.gabtaxi.gabtaxi_users_app" class="leading-snug">
                        <p class="text-sm text-gray-300">Download on</p>
                        <p class="text-white text-lg font-medium"> Play Store</p>
                    </a>
    
                    </button>
              </div>
            <div class="flex justify-center pt-20 w-10/12 mx-auto">
                    <img v-lazy="ridePic" class="absolute pt-10 h-[650px]" alt="" :class="{'hidden': !isEng}">   
                    <img v-lazy="ridePicFrench" class="absolute pt-10 h-[650px]" alt="" :class="{'hidden': !isFrench}">   
                    <img v-lazy="ridePicPort" class="absolute pt-10 h-[650px]" alt="" :class="{'hidden': !isPort}">   
                    <img v-lazy="smallScales" class="" alt="">  
            </div>
            <div class="flex flex-col w-full mx-auto sm:pl-20  pl-10 lg:pl-0">       
                <p class="sm:text-3xl text-2xl font-bold pt-20">Download the Driver App</p>  

                 <div class="">
                    <span>
                        <img v-lazy="one" alt="" class="pt-10">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('find') }} </p>
                        <p class="text-gray-600 w-2/3">{{ $t('plan') }}.</p>
                    </span>
                    <span>
                        <img v-lazy="two" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('earn') }}</p>
                        <p class="text-gray-600 pt-4 w-2/3">{{ $t('get_numerous_rides') }}</p>
                    </span>
                    <span>
                        <img v-lazy="three" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('earn_money_everyweek') }}</p>
                        <p class="text-gray-600 w-2/3 pt-4">{{ $t('drive_with_gab') }}</p>
                    </span>
                </div> 

            </div>

            <div class="flex gap-5 pt-10 justify-center sm:justify-normal sm:pl-20 lg:pl-0">
                    <button class="flex items-center gap-2 py-2 bg-black px-7 rounded-xl leading-tight ">
                    <i class="fa-brands fa-apple text-4xl text-white"></i>
                    <a href="https://apps.apple.com/ng/app/gab-driver-drive-to-earn/id1670609398" class="text-white">
                        <p class="text-sm text-gray-300 leading-tight">Download on</p>
                        <p class="text-lg font-medium">App Store</p>
                    </a>
                    </button>
                    <button class="flex items-center leading-tight gap-2 bg-black px-6 py-2 rounded-xl ">
                    <img v-lazy="gpLogo" alt="" class="w-[25px]">
                    <a href="https://play.google.com/store/apps/details?id=com.gabtaxi.driver" class="leading-snug">
                        <p class="text-sm text-gray-300">Download on</p>
                        <p class="text-white text-lg font-medium">Play Store</p>
                    </a>
    
                    </button>
              </div>

            <div class="flex justify-center pt-10 w-10/12 mx-auto">
                    <img v-lazy="drivePic" class="absolute pt-12 h-[650px]" alt="" :class="{'hidden': !isEng}">   
                    <img v-lazy="drivePicFrench" class="absolute pt-20 h-[650px]" alt="" :class="{'hidden': !isFrench}">   
                    <img v-lazy="drivePicPort" class="absolute pt-20 h-[650px]" alt="" :class="{'hidden': !isPort}">   
                    <img v-lazy="blackScales" class="" alt="">  
                </div>
        
        </div>
    
        

    <HomeFooter/>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import HomeFooter from '../components/HomeFooter.vue'
    export default{
        data(){
            return{
                one:require('@/assets/one.svg'),
                two:require('@/assets/two.svg'),
                three:require('@/assets/three.svg'),
                downloadCenter: require('../assets/downloadCenter.svg'),
                ridePic:require('../assets/ridePic.png'),
                ridePicFrench:require('../assets/ridePicFrench.png'),
                ridePicPort:require('../assets/ridePicPort.png'),
                gpLogo:require('@/assets/gpLogo.svg'),
                drivePic: require('../assets/drivePic.png'),
                drivePicFrench: require('../assets/drivePicFrench.png'),
                drivePicPort: require('../assets/drivePicPort.png'),
                blackDownloadCenter: require('../assets/blackDownloadCenter.svg'),
                smallScales:require('../assets/smallScales.svg'),
                blackScales: require('../assets/blackScales.svg')
            }
        },
        components:{
            NavBar,
            HomeFooter
        },
        mounted(){
            scrollTo(0,0)
        },
        computed:{
        isFrench(){
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en';
        },
        
    },
    }
</script>