<template>
      <SpinnerLoader v-if="loading"/>
  <div class="body" v-else>
    <router-view/>  
  </div>

</template>

<script>
import SpinnerLoader from './components/SpinnerLoader.vue'
export default{
  components:{
    SpinnerLoader,
  },
  data(){
    return{
      loading:true
    }
  },
  methods:{
  },

  mounted(){
    setTimeout(() =>{
      this.loading = false;
    }, 3000)
  }
}
</script>

<style>
.body{
  max-width: 1440px;
  margin: auto;
}

</style>

