<template>
  <div>
    <HeroSection/>
    <DriveEarn/>
    <HowGabWorks/>
    <NeedRide/>
    <!-- <BlognNews/> -->
    <DownloadGab/>
    <HomeFooter/>
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';
import DriveEarn from '@/components/DriveEarn.vue';
import HowGabWorks from '@/components/HowGabWorks.vue';
import NeedRide from '@/components/NeedRide.vue';
// import BlognNews from '@/components/BlognNews.vue';
import DownloadGab from '@/components/DownloadGab.vue';
import HomeFooter from '@/components/HomeFooter.vue';

  export default{
    components:{
      HeroSection,
      DriveEarn,
      HowGabWorks,
      NeedRide,
      // BlognNews,
      HomeFooter,
      DownloadGab
    }
  }
</script>