<template>
    <div class="w-10/12 mx-auto">
        <NavBar/>
    </div>
    <div>
        <p class="lg:text-6xl sm:text-4xl text-xl mx-auto w-full flex justify-center items-center h-[100%] pt-40">{{ $t('oops') }}</p>
        
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
export default{
    components:{
        NavBar,
    },
}
</script>