<template>
    <!-- //lg:my-52 -->
    <div class="lg:h-[427px] sm:h-[700px] h-[700px] sm:w-10/12  bg-[url('@/assets/street.svg')] lg:mb-52 my-20 w-full mx-auto sm:rounded-2xl ">
        <div class="lg:h-[427px] sm:h-[700px] h-[700px] lg:bg-customBlue/80 bg-customBlue/90 sm:rounded-2xl w-full">
            <div class="flex flex-col lg:flex-row items-center lg:items-stretch lg:justify-normal justify-center">
                <div class="lg:pt-32 pt-10 lg:pl-10 justify center sm:justify-normal">
                    <p class="sm:text-4xl text-3xl text-white text-center lg:text-left font-bold "
                    :class="{'lg:w-3/4': isFrench, 'text-left':isFrench, 'px-2':isFrench}">{{ $t('download_gab') }}</p> 

                    <div class="pt-10 flex gap-4 flex-row  lg:items-stretch items-center m-auto lg:m-0 w-full lg:justify-normal justify-center">
                <button class="flex items-center gap-2 py-2 bg-black px-7 rounded-xl leading-tight ">
                  <i class="fa-brands fa-apple text-4xl text-white"></i>
                  <a href="https://apps.apple.com/ng/app/gab-safe-and-affordable/id1670627457" class="text-white">
                    <p class="text-sm text-gray-300 leading-tight">Download on</p>
                    <p class="text-lg font-medium">App Store</p>
                  </a>
                </button>
                <button class="flex items-center leading-tight gap-2 bg-black px-6 py-2 rounded-xl ">
                  <img v-lazy="img6" alt="" class="w-[25px]">
                  <a href="https://play.google.com/store/apps/details?id=com.gabtaxi.gabtaxi_users_app" class="leading-snug">
                    <p class="text-sm text-gray-300">Download on</p>
                    <p class="text-white text-lg font-medium">Play Store</p>
                  </a>
  
                </button>
              </div>
                    <div class="flex justify-center sm:block">
                    <div class="flex gap-5 lg:pt-16 pt-10  flex-col "
                    :class="{'sm:flex-col':isFrench, 'sm:flex-row ' :isEng,'lg:flex-row':isFrench, 'sm:items-center': isFrench, 'lg:items-stretch': isFrench, 'sm:flex-col':isPort, 'items-center': isPort, 'lg:flex-row':isPort, }">
                <router-link to="/download-center" class=" font-medium gap-2 text-white lg:text-xl text-2xl flex items-center"
                        >{{ $t('sign_up_to_drive') }}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                </router-link>
                    <router-link to="/download-center" class="font-medium flex gap-2 text-white text-2xl lg:text-xl items-center">{{ $t('sign_up_to_ride') }}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                </router-link>
                    </div>

                    </div>
                    <div class="lg:hidden flex justify-center pt-10">
                        <img v-lazy="img7" alt="" class="h-[260px]" :class="{'hidden': !isEng}">
                        <img v-lazy="downloadFrench" alt="" class="h-[260px]" :class="{'hidden': !isFrench}">
                        <img v-lazy="downloadPort" alt="" class="h-[260px]" :class="{'hidden': !isPort}">
                    </div>
                  
                </div> 
                <div class="">
                    <img v-lazy="img8" class="absolute hidden right-72 h-[228px]" :class="{'right-0': isFrench, 'pl-96':isFrench,'pr-0':isFrench, 'lg:block': isEng, 'lg:hidden': isFrench, 'lg:hidden': isPort}">
                    <img v-lazy="frenchUpsideDown" class="absolute hidden right-72 h-[228px]" :class="{'right-0': isFrench, 'pl-96':isFrench,'pr-0':isFrench, 'lg:hidden': isEng, 'lg:block': isFrench, 'lg:hidden': isPort}">
                    <img v-lazy="portUpsideDown" class="absolute hidden right-72 h-[228px]" :class="{'right-0': isFrench, 'pl-96':isFrench,'pr-0':isFrench, 'lg:hidden': isEng, 'lg:hidden': isFrench, 'lg:block': isPort}">
                    
                    
                    <img v-lazy="img9" alt="" class="pt-48 pl-28 hidden absolute h-[428px]" :class="{'right-96': isFrench, 'pr-40':isFrench, 'lg:block': isEng, 'lg:hidden': isFrench, 'lg:hidden': isPort}">
                    <img v-lazy="frenchDownsideUp" alt="" class="pt-48 absolute pl-28 hidden h-[428px]" :class="{'right-96': isFrench, 'pr-40':isFrench, 'lg:hidden': isEng, 'lg:block': isFrench, 'lg:hidden': isPort}">
                    <img v-lazy="portDownsideUp" alt="" class="pt-48 absolute pl-28 hidden h-[428px]" :class="{'right-96': isFrench, 'pr-40':isFrench, 'lg:hidden': isEng, 'lg:hidden': isFrench, 'lg:block': isPort}">

                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                img6: require('@/assets/gpLogo.svg'),
                img7: require('@/assets/downloadiPhone.png'),
                downloadFrench: require('@/assets/frenchDownloadiPhone.png'),
                downloadPort: require('@/assets/portDownloadiPhone.png'),
                img8: require('@/assets/upsideDown.png'),
                img9: require('@/assets/downsideUp.png'),
                frenchUpsideDown: require('@/assets/frenchUpsideDown.png'),
                frenchDownsideUp: require('@/assets/frenchDownsideUp.png'),
                portDownsideUp : require('@/assets/portDownsideUp.png'),
                portUpsideDown: require('@/assets/PortUpsideDown.png'),
            }
        },
        computed:{
        isFrench(){ 
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en';
        }
    }

    }
</script>