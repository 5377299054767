<template>
    <div class="bg-blue-500">
        <div class="sm:w-10/12  m-auto flex justify-between lg:flex-row flex-col items-center lg:space-y-0 space-y-10 lg:pb-0 pb-10">
            <div>
                <p class="text-white lg:text-4xl text-2xl text-center sm:text-left font-bold lg:pt-0 pt-16">{{ $t('download_gab') }}</p> 
        
                <div class="pt-10 flex gap-4 sm:flex-row  lg:items-stretch items-center m-auto lg:m-0 w-full lg:justify-normal justify-center flex-col">
                    <button class="flex items-center gap-2 py-2 bg-black px-7 rounded-xl leading-tight ">
                    <i class="fa-brands fa-apple text-4xl text-white"></i>
                    <a href="https://apps.apple.com/ng/app/gab-safe-and-affordable/id1670627457" class="text-white">
                        <p class="text-sm text-gray-300 leading-tight">Download on</p>
                        <p class="text-lg font-medium">App Store</p>
                    </a>
                    </button>
                    <button class="flex items-center leading-tight gap-2 bg-black px-6 py-2 rounded-xl ">
                    <img v-lazy="img10" alt="" class="w-[25px]">
                    <a href="https://play.google.com/store/apps/details?id=com.gabtaxi.gabtaxi_users_app" class="leading-snug">
                        <p class="text-sm text-gray-300">Download on</p>
                        <p class="text-white text-lg font-medium">Google Play</p>
                    </a>
    
                    </button>
              </div>
            </div>
            <div class="sm:w-[400px]"> 
            <img v-lazy="img11" alt="" :class="{'hidden': !isEng}" class="">
            <img v-lazy="portugueseRide" alt="" :class="{'hidden': !isPort}">
            <img v-lazy="frenchRide" alt="" :class="{'hidden': !isFrench}">    
            </div>

        </div>        
    </div>
</template>

<script>
    export default{
        data(){
            return{
                img10: require('@/assets/gpLogo.svg'),
                img11: require('@/assets/downloadRides.png'),
                portugueseRide: require('@/assets/portugueseRide.png'),
                frenchRide: require('@/assets/frenchRide.png')
            }
        },
        computed:{
        isFrench(){
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en';
        },
        
      },
    }
</script>