<template>
    <!-- This is the Ride Options page -->
    <div class="pt-10">
        <p class="font-bold text-4xl text-center">{{ $t('ride_options') }}</p>

        <!-- Standard Option -->
        <div class="flex items-center pt-20 justify-between lg:pl-36 lg:flex-row flex-col-reverse w-10/12 lg:w-full mx-auto lg:mx-0">
            <span class="lg:w-5/12 w-full">
                <p class="text-3xl font-medium pb-4 pt-11 lg:pt-0">{{ $t('standard') }}</p>
                <p class="text-gray-600 pb-5">{{ $t('standard_text') }}</p>
                <router-link to="/download-center" class="text-blue-500 underline">{{ $t('get_started') }}</router-link>
            </span>
            <div class="bg-gray-50 flex items-center justify-center py-20 lg:rounded-l-2xl lg:w-1/2 w-full cursor-pointer rounded-none sm:h-[500px] h-[400px]">
                <img v-lazy="standardEngCar" alt="" class="image w-[400px]" :class="{ 'hidden':!isEng }">                
                <img v-lazy="standardCar" alt="" class="image w-[400px]" :class="{ 'hidden':!isPort }">                
                <img v-lazy="standardCar" alt="" class="image w-[400px]" :class="{ 'hidden':!isFrench }">                
            </div>

        </div>

        <!-- Comfort Option -->
        <div class="flex lg:pt-40 pt-20 justify-between items-center lg:pr-36 lg:flex-row flex-col lg:w-full w-10/12 mx-auto lg:mx-0">
            <div class="bg-gray-50 flex items-center justify-center py-20 lg:rounded-r-xl rounded-none lg:w-1/2 w-full  cursor-pointer sm:h-[500px] h-[400px]">
                <img v-lazy="comfortCar" class="image w-[400px]" alt="">                
            </div>
            <span class="lg:w-5/12 w-full lg:pt-0 pt-10">
                <p class="text-3xl font-medium pb-4">{{ $t('comfort') }}</p>
                <p class="text-gray-600 pb-5">{{ $t('comfort_text') }} </p>
                <router-link to="/download-center" class="text-blue-500 underline">{{ $t('get_started') }}</router-link>
                
            </span>


        </div>

        <!-- VIP Option -->
        <div class="flex items-center lg:pt-40 pt-20 justify-between lg:pl-36 lg:flex-row flex-col-reverse lg:w-full w-10/12 mx-auto lg:mx-0">
            <span class="lg:w-5/12 lg:pt-0 pt-10">
                <p class="text-3xl font-medium pb-4">VIP</p>
                <p class="text-gray-600 pb-5">{{ $t('vip_text') }}</p>
                <router-link to="/download-center" class="text-blue-500 underline">{{ $t('get_started') }}</router-link>
                
            </span>
            <div class="bg-gray-50 flex items-center justify-center py-20 lg:rounded-l-xl rounded-none lg:w-1/2 w-full cursor-pointer sm:h-[500px] h-[400px]">
                <img v-lazy="vipCar" class="image w-[500px]" alt="">                
            </div>

        </div>

        <!-- Tricycle Option -->
        <div class="flex items-center lg:pt-40 pt-20 justify-between lg:pr-36 lg:flex-row flex-col lg:w-full w-10/12 lg:mx-0 mx-auto lg:mb-36 mb-20">
            <div class="bg-gray-50 flex items-center justify-center py-20 rounded-r-xl lg:w-1/2 w-full image-container cursor-pointer sm:h-[500px] h-[400px]">
                <img v-lazy="tricycleCar" alt="" class="image w-[400px]" :class="{'hidden': !isEng}">                
                <img v-lazy="PortTricycle" alt="" class="image w-[400px]" :class="{'hidden': !isFrench}">                
                <img v-lazy="PortTricycle" alt="" class="image w-[400px]" :class="{'hidden': !isPort}">                
            </div>
            <span class="lg:w-5/12 pt-10 lg:pt-0">
                <p class="text-3xl font-medium pb-4">{{ $t('tricycle') }}</p>
                <p class="text-gray-600 pb-5">{{ $t('tricycle_text') }}</p>
                <router-link to="/download-center" class="text-blue-500 underline">{{ $t('get_started') }}</router-link>
                
            </span>


        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                standardCar: require('@/assets/standardCar.png'),
                standardEngCar: require('@/assets/standardEngCar.png'),
                comfortCar: require('@/assets/comfortCar.png'),
                vipCar: require('@/assets/vipCar.png'),
                tricycleCar: require('@/assets/tricycleCar.png'),                
                PortTricycle: require('@/assets/PortTricycle.png'),                
            }
        },

        computed:{
        isFrench(){ 
            return this.$i18n.locale === 'fr';
        },
        isPort(){
            return this.$i18n.locale === 'portuguese';
        },
        isEng(){
            return this.$i18n.locale === 'en';
        }
        }

        
    }
</script>

<style>
    .image{
        /* width: 300px; */
        transition: transform 0.3s;
        overflow: hidden;
    }
    .image:hover{
        transform:scale(1.4);
        overflow: hidden;
    }

    @media(max-width:768px){
        .image{
            pointer-events: none;
        }
    }

    @media(max-width:375px){
        .image{
            pointer-events:none
        }
    }

</style>