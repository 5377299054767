import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './main.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { createI18n } from 'vue-i18n';
import en from './i18n/en';
import fr from './i18n/fr';
import portuguese from './i18n/portuguese';
import getDefaultLanguage from './i18n/defaultLanguage';
import VueLazyload from 'vue-lazyload';

async function initializeApp() {
  const defaultLanguage = await getDefaultLanguage();

  const i18n = createI18n({
    legacy: false,
    locale: defaultLanguage,
    messages: {
      en,
      fr,
      portuguese,
    },
  });

  createApp(App).use(i18n).use(store).use(router).use(VueLazyload).mount('#app');
}

initializeApp();

