<template>
    <div class="pt-20 w-10/12 mx-auto pb-20">
        <div class="">
            <h1 class="text-center text-4xl text-gray-900 font-bold">{{ $t('drive') }}</h1>

            <div class="flex lg:pt-28 lg:justify-between lg:flex-row lg:items-start flex-col pt-16 ">
                
                <img v-lazy="img12" alt="" class="w-10/12 lg:w-2/5 mx-auto lg:mx-0">
                <img v-lazy="img13" alt="" class="absolute lg:left-0 pt-20 lg:pl-96 right-0 sm:pl-20 pl-40">
                <img v-lazy="img14" alt="" class="absolute left-0 sm:pr-0 pr-40 sm:pt-80 pt-60">
            
                <div class="lg:w-2/5 pt-10 lg:pt-0">
                    <span>
                        <img v-lazy="img15" alt="">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('find') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('plan') }}</p>
                    </span>
                    <span>
                        <img v-lazy="img16" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('earn') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('get_numerous_rides') }}</p>
                    </span>
                    <span>
                        <img v-lazy="img17" alt="" class="pt-7">
                        <p class="text-gray-900 font-bold text-2xl pt-7">{{ $t('earn_money_everyweek') }}</p>
                        <p class="text-gray-600 w-11/12 pt-4">{{ $t('drive_with_gab') }}</p>
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default{
        data(){
            return{
                img12: require('@/assets/driveImg.svg'),
                img13: require('@/assets/driveRating1.svg'),
                img14: require('@/assets/driveRating2.svg'),
                img15: require('@/assets/one.svg'),
                img16: require('@/assets/two.svg'),
                img17: require('@/assets/three.svg'),
            }
        }
    }
</script>