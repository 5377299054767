<template>
    <div class=" sm:bg-[url('@/assets/contactUs.png')] bg-[url('@/assets/contactUsSmall.png')] lg:bg-cover h-[400px] lg:h-[700px] bg-contain bg-no-repeat ">
        <div class="w-10/12 m-auto">
            <NavBar :text-color="textColor"/>

            <p class="text-white lg:text-4xl sm:text-3xl text-xl lg:pl-20  lg:pt-52 sm:pt-20 pt-20 lg:pb-10 sm:pb-5 font-medium" >
                {{ $t('contact_us') }}
            </p>

            <p class="text-white lg:text-5xl sm:text-xl text-2xl font-bold lg:pl-20" >
                {{ $t('questions') }}
                
            </p>
            <p class="text-white lg:text-5xl sm:text-xl text-2xl font-bold lg:pl-20 sm:pt-5" >{{ $t('get_in_touch') }}
               
            </p>

        </div>                
    </div>

    <div class="w-10/12 m-auto flex justify-between lg:flex-row flex-col">
        <div class="lg:w-1/2"> 
            <p class="text-4xl pb-10 lg:pt-40 lg:text-left text-center sm:pt-10">{{ $t('reach_out') }}
            </p>
            <form>
                <span class="flex flex-col pt-5 space-y-2">
                    <label class="text-lg font-medium">{{$t('your_email')}}</label>
                    <input type="text" placeholder="Enter email address" class="border border-gray-400 p-2 rounded-lg text-gray-500">                    
                </span>

                <span class="flex flex-col pt-5 space-y-2">
                    <label class="text-lg font-medium">{{$t('your_name')}}</label>
                    <input type="text" placeholder="Enter name" class="border border-gray-400 p-2 rounded-lg text-gray-500">                    
                </span>

                <span class="flex flex-col pt-5 space-y-2">
                    <label class="text-lg font-medium">{{$t('your_number')}}</label>
                    <input type="text" placeholder="Enter number" class="border border-gray-400 p-2 rounded-lg text-gray-500">                    
                </span>

                <span class="flex flex-col pt-5 space-y-2">
                    <label class="text-lg font-medium">{{$t('your_message')}}</label>
                    <textarea class="border border-gray-400 p-2 rounded-lg text-gray-500" rows="12" placeholder="Enter message"></textarea>                 
                </span>

                <button type="submit" class="bg-customBlue text-white px-8 py-2 rounded-xl mt-10"> {{$t('submit')}}</button>

            </form>
        </div>

        <div class="bg-customBlue text-white lg:w-1/3 lg:mt-40 mt-16 rounded-2xl">
            <span class="flex flex-col items-center pt-28">
                <img v-lazy="mail" alt="" class="w-[50px]">
                <p class="text-lg pt-5">{{ $t('email_support') }}</p>
                <p class="font-medium text-lg">support@gabtaxi.com</p>
            </span>

            <span class="flex flex-col items-center pt-24">
                <img v-lazy="call" alt="" class="w-[50px]">
                <p class="text-lg pt-5">{{ $t('call_us') }}</p>
                <p class="font-medium text-lg">+234 706 787 1000</p>
            </span>

            <span class="flex flex-col items-center pt-24 lg:pb-0 pb-24">
                <img v-lazy="send" alt="" class="w-[50px]">
                <p class="text-lg pt-5">{{$t('address1')}} </p>
                <p class="font-medium text-lg">{{ $t('address2') }}</p>
            </span>
        </div>
    </div>
    <HomeFooter/>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import HomeFooter from '@/components/HomeFooter.vue'
export default{
    data(){
        return {
                textColor: 'text-white', 
                mail:require('../assets/mail.svg'),
                call:require('../assets/call.svg'),
                send:require('../assets/send.svg')  
        }
    },
    components:{
        NavBar,
        HomeFooter
    }
}
</script>







