<template>
    <div>
    <p class="text-4xl font-bold text-center">{{ $t('download_app')}}</p>
    <div class="flex justify-center gap-6 py-8">
    <button class=" rounded-3xl px-9 font-medium py-3" @click="showRides" :class="{'bg-slate-100':!Rides, 'text-black':!Rides,'bg-gray-900':Rides, 'text-white':Rides}">{{$t('rides')}}</button>
    <button @click="showDrive" class=" rounded-3xl px-9 font-medium py-3" :class="{'bg-black':!Rides, 'text-white':!Rides, 'bg-slate-100':Rides, 'text-black':Rides,}">{{ $t('ride_driver') }}</button>
    </div>

    <div v-if="Rides">
        <downloadRides/>
    </div>
    <div v-else>
        <downloadDrive/>
    </div>
    </div>
</template>

<script>
    import downloadDrive from '../components/downloadDrive.vue'
    import downloadRides from './downloadRides.vue';
    export default{
        components:{
            downloadRides,
            downloadDrive
        },

        data(){
            return{
                Rides: false,
            } 
        },
        methods:{
            showRides(){
                this.Rides = true
            },
            showDrive(){
                this.Rides = false
            }
        }

    }
</script>