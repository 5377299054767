const messages={
    // NavBar
    home:'Lar',
    about_us: 'Sobre nós',
    services:'Serviços',
    contact:'Contate-nos',
    eng:'Inglês',
    fren:'Francês',
    port:'Português',
    blog:'Blog do Gab',
    become_driver:'Torne-se um motorista',
    rides:'Passeios',
    ride_driver:'Motorista',
    logistics:'Logística',
    emergency:"Serviços de emergência",

    // Hero Section
    safe:'Passeio seguro e acessível.',
    thousands:'Milhares de pessoas, organizações e empresas dependem de nossos serviços de transporte para se locomover pela cidade.',
    request:'SOLICITE UMA VIAGEM AGORA',

    // Request a ride view
    ride_with_ease:'Ande com facilidade, muito conforto, segurança e chegue com confiança.',
    request_ride: 'Solicite agora',

    // Drive and Earn
    drive:'Dirija e Ganhe',
    find:'Encontre viagens que se encaixem em um horário flexível',
    plan:'Você pode dirigir por quanto tempo e quantas vezes quiser. Durante a semana, fins de semana e noites - planeje sua direção de acordo com sua programação.',
    earn:'Ganhe mais dirigindo com Gab',
    get_numerous_rides:'Obtenha inúmeras solicitações de viagem e ganhe mais todos os dias.',
    earn_money_everyweek:'Ganhe dinheiro toda semana',
    drive_with_gab:'Quer ganhar dinheiro antes do dia de pagamento? Dirija com Gab agora.',

    // How Gab works
    how:'Como funciona o Gab',
    passenger:'Passageiro',
    driver:'Motorista',

    // Passenger Info and Driver Info
    request_trip:'Solicitar uma viagem',
    select_pickup:'Selecione sua coleta e destino e selecione sua opção de passeio.',
    start_trip: 'Iniciar viagem',
    driver_meets_you: 'O motorista se encontra com você com a ajuda de nosso serviço de GPS em tempo real e inicia a viagem.',
    match:'Combine com o motorista',
    gab_will_match_you: 'Gab irá combiná-lo com o próximo driver disponível que o levará',
    end_trip:'Terminar viagem e pagar',
    pay_for_your_trip:'Pague sua viagem com dinheiro ou cartão e taxe o motorista. Aproveite sua viagem com Gab.',
    pay_and_give_ratings:'Pagar e dar avaliações',
    pay_description: 'Pague sua viagem com dinheiro ou cartão e seu motorista',

    // Need a Ride?  
    need_ride:'Precisa de uma carona? Nós Gab você.',
    efficient_transport:'Transporte eficiente e confiável',
    quick_booking:'Processo de reserva rápido e fácil para transporte instantâneo com serviço 24 horas por dia para atender às suas necessidades de transporte a qualquer momento.',
    simplify_commute:'Simplifique seu deslocamento com Gab',
    streamlined_booking: 'Sistema de reservas simplificado para uma experiência sem estresse. Acompanhe o seu passeio em tempo real para maior segurança e conveniência.',
    experience_future:'Experimente o futuro do transporte compartilhado',
    intuitive:'Design de aplicativo intuitivo e fácil de usar para navegação sem esforço. Medidas de segurança aprimoradas para garantir um passeio seguro.',

    // Blog and News
    blog_and_news:'Blogue e notícias',
    see_all: 'Ver tudo',
    prioritize_safety: "É importante que os passageiros priorizem sua segurança ao utilizar o serviço...",
    platforms_revolutionized: 'No mundo dos transportes em rápida evolução, as plataformas de e-hailing revolucionaram...',
    launch_hailing_operations: 'E.F. Network Limited lançará suas operações de e-hailing no país como...',

    // Download Gab Now
    download_gab: 'Baixe agora o aplicativo Gab',
    sign_up_to_drive:'Inscreva-se para dirigir',
    sign_up_to_ride:'Inscreva-se para montar',

    // Home Footer
    company:'Empresa',
    aboutUs:'Sobre nós',
    gab_blog:'Blog do Gab',
    support_center:'Centro de Apoio',
    legal:'Jurídico',
    terms:'Termos e Condições',
    privacy_policy:'Política de Privacidade',
    copyright:'Copyright 2023 Gab Táxi. Todos os direitos reservados.',
    address :'Hala Hotel, Bisau, Guinea Bisau, V96H+55',
    address1 :'Hala Hotel, Bisau',
    address2 :'Guinea Bisau, V96H+55',

    // Services Rides
    got_you_covered:"Precisa de uma carona? Estamos protegendo você",
    get_app:"Baixe o aplicativo",

    // Rides Options
    ride_options:'Opções de viagem',
    standard:'Padrão',
    standard_text:"Assim como seu táxi azul e branco comum, a opção queda padrão é econômica e leva você ao local desejado sem gastar muito.\n\n Embora o queda padrão possa não oferecer os recursos luxuosos de outras opções de corrida, como transporte ar condicionado, proporciona uma viagem confortável e segura até ao seu destino. Os motoristas são examinados e treinados para garantir um alto nível de profissionalismo, e os veículos são mantidos regularmente para garantir a confiabilidade.",
    get_started:'Iniciar',
    comfort:'Conforto',
    comfort_text:'Um dos principais diferenciais do Gab é a opção conforto, que proporciona aos passageiros uma viagem confortável por um preço acessível. Os veículos utilizados para a opção conforto são bem conservados, limpos e equipados com poltronas confortáveis ​​e ar-condicionado, garantindo aos passageiros uma viagem agradável e confortável.',
    vip:'VIP',
    vip_text:'Para os passageiros que querem viajar com estilo, o Gab oferece uma opção VIP. Esta opção é perfeita para quem deseja chegar ao seu destino com luxo e conforto. Os veículos utilizados para a opção VIP são carros de luxo projetados para proporcionar uma experiência luxuosa. Eles são equipados com comodidades premium, como assentos de couro, ar-condicionado e sistemas de entretenimento, garantindo que os passageiros tenham uma experiência de primeira classe.',
    tricycle:'Triciclo',
    tricycle_text:"Oferecemos a opção de triciclo para os usuários das vilas, onde os táxis não estão disponíveis e também onde os triciclos não são permitidos nos municípios. As experiências positivas do usuário que oferecemos são inigualáveis ​no setor, porque consideramos a necessidade de cada usuário de táxi.",

    // Service Drivers
    drive_earn:'Dirija e ganhe em seus próprios termos',
    how_works:'Como funciona',
    choose_schedule:'Escolha seu próprio horário',
    choose_schedule_text:"Entendemos que a flexibilidade é importante e é por isso que oferecemos uma oportunidade única para os motoristas trabalharem em seu próprio horário. \n\nComo motorista do Gab Taxi, você tem a liberdade de escolher quando, onde e com que frequência trabalhar. Quer pretenda fazer da condução o seu trabalho a tempo inteiro ou apenas ganhar algum rendimento extra, oferecemos a flexibilidade que se adapta às suas necessidades.",
    support_success: 'Apoio e sucesso',
    support_success_text:"Acreditamos que nossos motoristas são a espinha dorsal de nossa empresa e estamos comprometidos em fornecer a eles o suporte de que precisam para ter sucesso. Oferecemos treinamento e recursos contínuos para ajudar nossos motoristas a melhorar suas habilidades e expandir seus negócios.\n\n Na Gab Taxi, nos orgulhamos de nosso compromisso com a segurança. Examinamos cuidadosamente todos os nossos motoristas para garantir que eles tenham um registro de direção limpo e conheçam as leis e regulamentos de trânsito. Além disso, fornecemos aos nossos motoristas dicas e práticas recomendadas para se manter seguro na estrada.",
    competitive_rates:'Tarifas competitivas',
    competitive_rates_text:'Também oferecemos tarifas competitivas e incentivos para ajudar nossos motoristas a maximizar seus ganhos. Com Gab Taxi, você pode ganhar dinheiro em seus próprios termos, enquanto desfruta dos benefícios de fazer parte de uma empresa dinâmica e em crescimento. \n\nJunte-se à equipe Gab Taxi hoje e comece a dirigir em seus próprios termos.',

    // Download Drive
    download_gab_now:'Baixar Gab Driver Agora',

    // Emergency Services
    emergency_services:'Serviços de emergência',
    emergency_services_text:'Os serviços de emergência Gab Taxi permitem que os usuários reservem ambulâncias, caminhões de reboque, liguem para a linha direta de segurança da polícia, gerenciamento de resíduos e serviços de bombeiros durante uma emergência. Gab fornece uma maneira rápida e fácil para os usuários acessarem os serviços de emergência na ponta dos dedos. Com o Gab Taxi, os usuários podem solicitar ambulância para emergências médicas, guinchos para quebras ou acidentes, ligar para a central de segurança da polícia em caso de ameaças à segurança e para o corpo de bombeiros em caso de incêndio.',
    coming_soon:'Em breve',
    register:'Registro',
    download_passenger_app:'Baixe o aplicativo Gab passageiro e cadastre-se como usuário.',
    set_location:'Defina seu local de emergência',
    make_request: 'Selecione a sua localização e faça o seu pedido',
    emergency_services_respond:'Selecione a sua localização e faça o seu pedido',
    cater_to_emergencies:'Os serviços que atendem a emergências respondem e vão até você.',

    // Gab Logistics
    gab_logistics:'Gab Logística',
    gab_logistics_text:'Oferecemos ao público em geral a opção de ter seus próprios mensageiros pessoais, que farão suas tarefas por eles sem viver no conforto de seus escritórios ou residências. Os motoristas do Gab também fazem entregas e coletas.',
    select_dropoff:'Definir embarque e desembarque',
    login_to_app:'Faça login no aplicativo, selecione logística e escolha seu endereço de coleta e entrega.',
    item_delivered:'Seu item é entregue',
    item_pickedUp:'Seu item é retirado e entregue à sua porta.',

    // Gab Blog
    safe_lifestyle:'O estilo de vida seguro e acessível para você.',
    download_app:'Download our Apps',
    categories:'Categorias',
    news:'Notícias',
    safety:'Segurança',
    places:'Lugares',
    tips:'Pontas',
    search:'Procurar',
    recent:'Postagens de blog recentes',
    rise_of_ehailing:'O surgimento de plataformas de e-hailing, como compartilhamento de viagens e serviços de entrega de comida, revolucionou a maneira como as pessoas se deslocam e acessam vários bens e serviços.',
    gab_comfort:'Como Gab traz conforto para você',
    safety_for_public:'Segurança para o público?',
    bustling_metropolis:'Abuja, capital da Nigéria, é uma metrópole movimentada que oferece muitas oportunidades.',
    blog_post:'Todas as postagens do blog',
    advent_of_technology:'O advento da tecnologia revolucionou vários setores.',
    learn_more:'Saber mais',
    organize_digital:'Organize seus ativos digitais com uma nova metodologia e pronto.',
    gab_owned:'Gab é propriedade da EF Network Limited e EFGroup.',
    first_indigenous:'O primeiro aplicativo de táxi Gab de e-hailing indígena',
    ehailing_services:'Os serviços de e-hailing tornaram-se cada vez mais populares agora.',
    giant_industry:'Um gigante da indústria de alta tecnologia e filho da terra tem uma empresa.',
    
    // Contact Us
    contact_us: 'Contate-nos',
    questions:'Questões ou Comentários',
    get_in_touch:'Entrar em contato',
    reach_out:'Estenda a mão para nós!',
    your_email:'Seu email',
    your_name:'Seu nome',
    your_number:'Seu número de telefone',
    your_message:'Sua mensagem',
    email_support:'Suporte por e-mail em',
    call_us:'Ligue para nós em',

    // About Us
    affordable:'Acessível',
    about_us_text:'Bem-vindo ao Gab Taxi, o principal aplicativo de e-hailing que conecta você a opções de transporte confiáveis ​​e acessíveis. Na Gab Taxi, estamos comprometidos em fornecer aos nossos clientes viagens seguras, convenientes e sem complicações, seja para ir ao trabalho, fazer recados ou simplesmente explorar sua cidade.',
    safe_:'Seguro',
    efficient:'Eficiente',
    our_mission:'Nossa missão',
    we_prioritize: 'Nós priorizamos',
    Safety:' segurança',
    above_all:'acima de tudo',
    we_prioritize_text:'No Gab Taxi, examinamos cuidadosamente todos os nossos motoristas para garantir que eles tenham um histórico de direção limpo e conheçam as leis e regulamentos de trânsito. Além disso, oferecemos aos nossos motoristas treinamento e suporte contínuos para garantir que eles estejam preparados para lidar com qualquer situação que possa surgir.',
    there_are:'Não há',
    no_hidden_fees:'taxas escondidas',
    or:' ou ',
    surprise_charges: 'cobranças surpresa',
    surprise_text:"Acreditamos que a transparência é a chave para construir a confiança de nossos clientes. É por isso que oferecemos preços antecipados, para que você saiba exatamente quanto custará sua viagem antes mesmo de reservá-la. Aceitamos uma variedade de métodos de pagamento, incluindo cartões de crédito, cartões de débito e dinheiro, facilitando o pagamento de sua viagem da maneira que for mais conveniente para você.",
    exceptional_experience:'Experiência excepcional apenas ',
    for_you:'para você ',
    exceptional_experience_text:'Nossa equipe se dedica a criar uma experiência excepcional para nossos usuários, desde o momento em que você reserva sua viagem até o momento em que chega ao seu destino. Entendemos a importância da pontualidade, e é por isso que trabalhamos duro para garantir que nossos motoristas sejam rápidos e confiáveis, chegando ao seu local em tempo hábil.',
    
    
    // Privacy Policy
    privacy_policy_text:'A GabTaxi está empenhada em proteger a privacidade de seus motoristas e passageiros e garantir a segurança de suas informações pessoais. Este Aviso de Privacidade descreve como GabTaxi processa e lida com dados pessoais em conformidade com as leis e regulamentos de proteção de dados.',
    personal_data:'1.   Dados pessoais que processamos',
    personal_data_text:'A GabTaxi trata os seguintes dados pessoais:',
    contact_details:'Detalhes de contato: nome, número de telefone e endereço de e-mail.',
    profile_info:'Informações do perfil: foto do perfil, endereços salvos, idioma e preferências de comunicação.',
    geolocation:'Geolocation: local de embarque e desembarque, progressão da viagem e destino final da viagem.',
    payment_info:'Informações de pagamento: o valor cobrado e o cartão de pagamento utilizado.',
    communication_and_correspondence : 'Registros de comunicação e correspondência: histórico de bate-papo com motoristas ou agentes de atendimento ao cliente.',
    limited_identification:'Dados de identificação limitados do dispositivo: endereço IP no qual o aplicativo GabTaxi está instalado.',
    data_about_usage:'Dados sobre o uso de serviços de transporte: status da viagem, horários e comportamento dos passageiros.',
    data_related:'Dados relacionados à comunicação por meio de mensagens instantâneas diretamente no aplicativo GabTaxi: data e hora da comunicação e seu conteúdo.',
    other_user_generated_content:'Outro conteúdo gerado pelo usuário: fotos de veículos estacionados.',
    purpose_of_processing:'2.   Finalidade do Processamento',
    gab_processes:'A GabTaxi trata os dados pessoais para as seguintes finalidades:',
    provide_transport:'Para fornecer serviços de transporte: conectar passageiros com motoristas, mostrar os veículos disponíveis e facilitar o embarque e desembarque com eficiência.',
    smooth_running:'Garantir o bom andamento dos serviços de transporte: garantir que os passageiros cheguem ao destino, cobrar corretamente e manter a frota.',
    optimize_app:'Para otimizar o aplicativo: notificar os passageiros sobre atualizações, conectar os passageiros com serviços de internet, celular e telefone e manter as contas seguras por meio de verificações de autenticação e verificação.',
    improve_service:'Melhorar o serviço: analisar a cobertura geográfica, recomendar rotas eficientes e garantir que os veículos estejam nos locais mais convenientes para os clientes.',
    process_payment:"Para processar o pagamento: para processar os detalhes de pagamento para carona em nome dos motoristas e para os veículos alugados da GabTaxi. Manter e promover padrões: coletar feedback, resolver disputas e garantir a segurança do usuário e a conformidade com os termos e condições.",
    communicate_with_passengers:'Para se comunicar com os passageiros: para enviar comprovantes de viagem, recibos e atualizações de serviços.',
    legal_basis:'3.   Base legal',
    gab_processes_on_legal_basis:'A GabTaxi processa dados pessoais com base legal em:',
    contract_to_provide:'Contrato: para prestação dos serviços contratados através do aplicativo GabTaxi.',
    legitimate_interests:'Interesses legítimos: otimizar o aplicativo, melhorar o serviço, manter e promover padrões e se comunicar com os passageiros.',
    legal_obligations:'Obrigações legais: para cumprir as obrigações legais, como requisitos fiscais e contábeis.',
    consent:'Consentimento: para processar dados pessoais para determinados fins onde o consentimento foi dado.',
    data_subject:'4. Direitos do Titular dos Dados',
    drivers_and_passengers:'Os condutores e passageiros têm os seguintes direitos relativamente aos seus dados pessoais:',
    right_to_access:'Direito de acesso: para solicitar o acesso aos dados pessoais detidos pela GabTaxi.',
    right_to_rectification:'Direito de retificação: Para solicitar que os dados pessoais sejam restritos',
    right_to_erasure:'Direito ao apagamento: solicitar a eliminação dos dados pessoais.',
    right_to_restrict:'Direito de restringir o processamento: solicitar a restrição do processamento de dados pessoais.', 
    right_to_object:'Direito de oposição: de se opor ao processamento de dados pessoais para determinados fins',
    right_to_data:'Direito à portabilidade dos dados: solicitar uma cópia dos dados pessoais em formato estruturado e legível por máquina.',
    right_to_withdraw_consent:'Direito de retirar o consentimento: retirar o consentimento quando os dados pessoais são processados ​​com base no consentimento.',
    data_protection:'5.   Proteção de dados',
    gab_committed_to_security:'A GabTaxi está empenhada em garantir a segurança dos dados pessoais e implementou medidas técnicas e organizacionais para impedir o acesso, uso ou divulgação não autorizada de dados pessoais.',
    data_retention:'6.   Retenção de dados',
    gab_retains:'A GabTaxi retém os dados pessoais durante o tempo necessário à prestação dos serviços de transporte e durante o tempo exigido por lei.',
    contact_us_:'7.   Contate-nos',
    any_questions:'Se você tiver alguma dúvida ou preocupação sobre como a GabTaxi processa dados pessoais, entre em contato com nosso Diretor de Proteção de Dados em ',
    personalDataWeProcess:'Dados pessoais que processamos',
    purposeOfProcessing:'Finalidade do processamento',
    legalBasis:'Base legal',
    dataSubject:'Direitos do Titular dos Dados',
    dataProtection:'Proteção de dados',
    dataRetention:'Retenção de dados',
    contactUs:'Contate-nos',
    submit:'Enviar'


    
}

export default messages;