<template>
  <div class="bg-gray-50 sm:pb-36 pb-16">
    <div class="w-10/12 mx-auto">
      <!-- Navbar -->
      <NavBar/>
      </div>
      <div class="flex lg:justify-between lg:items-center flex-col-reverse lg:flex-row mx-0 lg:pl-0 sm:px-16 px-0">
        <div class="lg:w-6/12 w-full lg:pt-32 pt-10 lg:pl-32">
            <p class="lg:text-6xl sm:text-7xl text-4xl font-bold w-full text-gray-900 leading-tight sm:px-0 px-5">
              {{ $t('safe') }}
              </p>
            <p class="lg:pt-9 pt-5 text-gray-500 sm:px-0 px-5">
              {{ $t('thousands') }}</p>
            <div class="sm:block flex flex-col items-center">
              <div class="pt-10 flex gap-4 flex-row  items-center m-auto lg:m-0 w-full sm:justify-start justify-center ">
                    <button class="flex items-center gap-2 py-2 bg-black px-7 rounded-xl leading-tight ">
                    <i class="fa-brands fa-apple text-4xl text-white"></i>
                    <a href="https://apps.apple.com/ng/app/gab-safe-and-affordable/id1670627457" class="text-white">
                        <p class="text-sm text-gray-300 leading-tight">Download on</p>
                        <p class="text-lg font-medium">App Store</p>
                    </a>
                    </button>
                    <button class="flex items-center leading-tight gap-2 bg-black px-6 py-2 rounded-xl ">
                    <img v-lazy="gpLogo" alt="" class="w-[25px]">
                    <a href="https://play.google.com/store/apps/details?id=com.gabtaxi.gabtaxi_users_app" class="leading-snug">
                        <p class="text-sm text-gray-300">Download on</p>
                        <p class="text-white text-lg font-medium">Play Store</p>
                    </a>
    
                    </button>
              </div>
              <router-link to="/request-ride" class="pt-9 font-bold flex gap-2 text-gray-700 text-lg underline sm:px-0 px-5 ">
                {{ $t('request') }}
              </router-link>
            </div>
        </div>
        
        <div class=" flex sm:pt-16 w-full lg:w-2/5  lg:mr-0 heroCar">
          <img v-lazy="gabCar" alt="" class="lg:w-auto w-[600px] m-auto">
        </div>          
        </div>

    </div>    
  

  </template>

<script>
import NavBar from '@/components/NavBar.vue'
export default{
  components:{
    NavBar
  },
  data(){
    return{
      gpLogo:require('@/assets/gpLogo.svg'),
      gabCar:require('@/assets/gabCar.png'),
      
    }
  },
  computed:{
  isFrench(){
      return this.$i18n.locale === 'fr';
  },
  isPort(){
      return this.$i18n.locale === 'portuguese';
  },
  isEng(){
      return this.$i18n.locale === 'en';
  }
    
  }

}

</script>

<style>

  .heroCar{
    animation: slideInFromRight 1s ease-in;
  }
  @media(max-width: 414px){
    .heroCar{
      animation: none;
    }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

</style>